import {FormDataInterface} from "../../../../declarations";
import {defaultFormConfig} from "../../helper/helper";
import {drugs_signature, drugs_signature_doctor, radio, text} from "../../../inputFields/inputFields";

const drugs: FormDataInterface = {
    delete_drugs: {
        label: 'Törlés',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            row: 'drugs_row_2',
            button: 'drugs_button',
            hide: false,
            hideLabel: true,
            buttonColor: 'danger',
            colSize: '1',
            buttonIcon: 'remove',
            classes: 'button-shrink'
        },
        text: 'Törlés',
        validation: {
            required: false,
        },
        ...defaultFormConfig,
        valid: true
    },
    drugs_button: {
        label: 'További gyógyszer',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            displayedRows: [],
            hide: false,
            hideLabel: true,
            colSize: '12',
            buttonIcon: 'add',
            inputGroupTemplate: 'drugs_dynamic'
        },
        validation: {
            required: false,
        },
        ...defaultFormConfig
    },
    delete_infusion: {
        label: 'Törlés',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            row: 'infusion_row_2',
            button: 'infusion_button',
            hide: false,
            hideLabel: true,
            buttonColor: 'danger',
            colSize: '1',
            buttonIcon: 'remove',
            classes: 'button-shrink'
        },
        text: 'Törlés',
        validation: {
            required: false,
        },
        ...defaultFormConfig,
        valid: true
    },
    infusion_button: {
        label: 'További infúzió',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            displayedRows: [],
            hide: false,
            hideLabel: true,
            colSize: '12',
            buttonIcon: 'add',
            inputGroupTemplate: 'infusions_dynamic'
        },
        validation: {
            required: false,
        },
        ...defaultFormConfig
    },
    /*text,
    radio,
    drugs_signature*/
};

const drugs_only: FormDataInterface = {
    ...drugs,
    drugs_signature_doctor
};

export {drugs as default, drugs_only};
