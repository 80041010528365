import React from 'react';
import {IonButton, IonCol, IonIcon, IonRow} from "@ionic/react";
import Moment from "react-moment";

import {ChartFormInterface} from "../../store/declarations";
import {DATE_FORMAT_FULL} from "../../utils/consants/common";
import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";
import {alert, checkmark} from "ionicons/icons";
import {FormTypes} from "../../store/types";
import {EXAMINATION_FORM} from "../../utils/functions/constants";

interface ChartListInterface {
    hash: string;
    forms: ChartFormInterface[];
    editSubFormOnclickHandler: any;
    formType: FormTypes;
}

const SubFormList: React.FC<ChartListInterface> = (props) => {
    const dispatch = useDispatch();
    const {
        hash,
        forms,
        editSubFormOnclickHandler,
        formType
    } = props;

    let isValidBadgeTemplate = (examination: any) => {
        if (examination.locked) {
            return <IonIcon color="success" icon={checkmark}/>;
        }

        return <IonIcon color="danger" icon={alert}/>;
    };

    let subFormList: any = formType === EXAMINATION_FORM ? <p>Nincsenek vizsgálatok</p> : <p>Nincsenek gyógyszerkiadások</p>;

    if (forms.length > 0) {
        subFormList = forms.map((subForm, subFormIndex) => {
            return (
                <IonRow key={subForm.hash}>
                    <IonCol>
                        <h4>{subFormIndex + 1}. {formType === EXAMINATION_FORM ? 'Vizsgálat' : 'Gyógyszerkiadás'} {isValidBadgeTemplate(subForm)}</h4>
                        <h5>Dátum: <Moment date={new Date(+subForm.created_date)} format={DATE_FORMAT_FULL}/></h5>
                    </IonCol>
                    <IonCol>
                        <IonButton onClick={() => editSubFormOnclickHandler(hash, subForm.hash, formType)}
                                   item-end
                                   className="ion-float-right">Megtekintés</IonButton>
                        <IonButton onClick={() => dispatch(actions.getPdfByType([formType], hash, subForm.hash))}
                                   item-end
                                   className="ion-float-right ion-padding-end">Letöltés</IonButton>
                    </IonCol>
                </IonRow>
            );
        });
    }

    return (
        <>
            {subFormList}
        </>
    );
};

export default SubFormList;
