import React, {useEffect} from 'react';
import {IonContent, IonPage, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {useHistory} from "react-router";

import {routes} from "../../../utils/consants/routes";
import Spinner from "../../../components/Spinner/Spinner";
import _ from "lodash";
import form from "../../../store/static/forms/hospital/hospital";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import {HospitalState, ActiveFormState} from "../../../store/states";

const Create: React.FC = (props: any) => {
    const {
        updateActiveForm,
        activeForm,
        validateForm,
        doSave,
        setFormValidity,
        resetActiveForm,
        inputHandler
    } = ActiveFormState();
    const {createHospital} = HospitalState();

    const history = useHistory();
    const {match} = props;

    const initForm = () => {
        const newForm = _.cloneDeep(form);
        validateForm(newForm);
        updateActiveForm(newForm);
    };

    useIonViewDidEnter(() => {
        initForm();
    }, [match.params.id]);

    useIonViewWillLeave(() => resetActiveForm());

    const exit = () => {
        history.push(routes.hospitalList);
    };

    const save = () => {
        doSave(async () => {
            const payload = {
                hospital: {
                    name: activeForm.form_data.name.value,
                    value: activeForm.form_data.value.value
                }
            };
            createHospital(payload, history);
        });
    };

    useEffect(() => {
        if (activeForm) {
            setFormValidity(activeForm);
        }
    }, [activeForm, setFormValidity]);

    const drawForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={{
                save,
                exit,
            }}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Új klinika létrehozása</h1>

                {drawForm()}
            </IonContent>
        </IonPage>
    );
};

export default Create;
