const apiV1 = '/api/v1';
export const API_SYNC_SAVE = process.env.REACT_APP_API_BASE_URL + apiV1 + '/sync/save';
export const API_SYNC_GET = process.env.REACT_APP_API_BASE_URL + apiV1 + '/sync/get';
export const API_SYNC_GET_CHARTS = process.env.REACT_APP_API_BASE_URL + apiV1 + '/sync/get/charts';

export const API_TRANSLATE = process.env.REACT_APP_API_BASE_URL + '/api/v1/translate';

export const API_MAIL = process.env.REACT_APP_API_BASE_URL + apiV1 + '/mail';

export const API_PDF = process.env.REACT_APP_API_BASE_URL + apiV1 + '/pdf';

export const API_AUTH_DOCTOR_LOGIN = process.env.REACT_APP_API_BASE_URL + apiV1 + '/auth/doctor-login';
export const API_AUTH_ADMIN_LOGIN = process.env.REACT_APP_API_BASE_URL + apiV1 + '/auth/admin-login';

export const API_STATISTICS = process.env.REACT_APP_API_BASE_URL + apiV1 + '/stat';

export const API_EVENT_GET = process.env.REACT_APP_API_BASE_URL + apiV1 + '/event/get';
export const API_EVENT_NEW = process.env.REACT_APP_API_BASE_URL + apiV1 + '/event/create-event';
export const API_EVENT_SHOW = process.env.REACT_APP_API_BASE_URL + apiV1 + '/event/show';
export const API_EVENT_UPDATE = process.env.REACT_APP_API_BASE_URL + apiV1 + '/event/update-event';
export const API_EVENT_EXPORT = process.env.REACT_APP_API_BASE_URL + apiV1 + '/event/export-event';
export const API_EVENT_EXPORT_TEMPDIR = process.env.REACT_APP_API_BASE_URL + "/";


export const API_HOSPITAL_GET = process.env.REACT_APP_API_BASE_URL + apiV1 + '/hospital/get';
export const API_HOSPITAL_NEW = process.env.REACT_APP_API_BASE_URL + apiV1 + '/hospital/create-hospital';
export const API_HOSPITAL_SHOW = process.env.REACT_APP_API_BASE_URL + apiV1 + '/hospital/show';
export const API_HOSPITAL_UPDATE = process.env.REACT_APP_API_BASE_URL + apiV1 + '/hospital/update-hospital';
export const API_HOSPITAL_DELETE = process.env.REACT_APP_API_BASE_URL + apiV1 + '/hospital/destroy';

export const API_ACCREDITATION_GET = process.env.REACT_APP_API_BASE_URL + apiV1 + '/accreditation/get';

