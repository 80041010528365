import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonButton,
    IonCol,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonList,
    IonRow,
    useIonViewDidEnter,
    useIonViewDidLeave
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import {add, trash, pencilOutline, downloadOutline} from "ionicons/icons";

import {API_EVENT_EXPORT_TEMPDIR, API_EVENT_GET} from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import {routes} from "../../utils/consants/routes";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {DATE_FORMAT} from "../../utils/consants/common";
import * as actions from "../../store/actions";
import {EventInterface} from "../../store/declarations";
import Spinner from "../Spinner/Spinner";
import classes from "./EventList.module.scss";
import {
    CERTIFICATE_FORM,
    COVID_FORM,
    MEDICINE_FORM,
    PATIENT_FORM,
    PATIENT_SHORT_FORM
} from "../../utils/functions/constants";

interface EventListInterface extends RouteComponentProps {
    events: EventInterface[];
}

const EventList: React.FC<EventListInterface> = ({events}) => {
    const dispatch = useDispatch();
    const setEvents = (events: EventInterface[]) => dispatch(actions.setEvents(events));
    const [isEventsLoaded, updateIsEventsLoaded] = useState(false);
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const payload = {};
    const history = useHistory();

    const getEvents = () => {
        axios.post(API_EVENT_GET, payload, config)
            .then((response) => {
                    setEvents(response.data.payload);
                    updateIsEventsLoaded(true);
                }
            )
    };

    useIonViewDidEnter(() => {
        dispatch(actions.clearEvent());
        getEvents();
    });

    useIonViewDidLeave(() => {
        updateIsEventsLoaded(false);
    });

    const clickHandler = async (event: EventInterface, mainFormTypes: string[]) => {
        console.log(event);
        dispatch(actions.getChartByEvent(event, mainFormTypes));
        const routeSuffix = mainFormTypes.indexOf(COVID_FORM) >= 0 ? routes.homeCovid : routes.homeDefault;
        history.push(routes.userHome + routeSuffix);
    };

    const createEvent = () => {
        history.push(routes.eventNew)
    };

    const editURLWithID = (eventID: number) => {
        return routes.eventEdit.replace(":eventID", eventID.toString());
    };

    const deleteEvent = (eventID: number) => {
        if (!window.confirm('Biztosan törölni szeretné?')) {
            return;
        }

        const payload = {
            id: eventID,
            deleted: 1
        };

        return axios.put(apiEndpoints.API_EVENT_UPDATE, {event: payload}, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Event/List.ts] API_EVENT_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.go(0);
            })
            .catch((e) => {
                console.error('[Event/List.ts] API_EVENT_UPDATE', e);
            });
    };

    const exportEvent = (eventID: number) => {
        updateIsEventsLoaded(false);
        const payload = {
            id: eventID
        };

        return axios.post(apiEndpoints.API_EVENT_EXPORT, {event: payload}, config)
            .then(response => {
                const link = document.createElement('a');
                link.href = API_EVENT_EXPORT_TEMPDIR + response.data.payload;
                link.setAttribute('download', 'event-export.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                updateIsEventsLoaded(true);
            })
            .catch((e) => {
                console.error('[Event/List.ts] API_EVENT_EXPORT', e);
            });
    };

    const editEvent = (eventID: number) => {
        history.push(editURLWithID(eventID))
    };

    const listEvents = (events: any) => {
        if (!isEventsLoaded) {
            return <Spinner/>;
        }

        if (events.length === 0) {
            return <h1>Nincsenek események</h1>;
        }

        return events.map((event: any) => event.filtered ? null :
            <IonItem key={event.id} lines="none">
                <IonRow className={classes["content"]}>
                    <IonCol>
                        <h2 className={classes["content__title"]}>
                            {event.name}
                        </h2>

                        <p className={classes["content__description"]}>
                            <Moment date={event.start} format={DATE_FORMAT}/> - <Moment date={event.end}
                                                                                        format={DATE_FORMAT}/>
                        </p>
                    </IonCol>

                    <IonCol className={classes["content__actions"]}>
                        <div className={classes["link"]} onClick={() => editEvent(event.id)}>
                            <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                     icon={pencilOutline}/>
                        </div>
                        <div className={classes["link"]} onClick={() => deleteEvent(event.id)}>
                            <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                     icon={trash}/>
                        </div>
                        <div className={classes["link"]} onClick={() => exportEvent(event.id)}>
                            <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                     icon={downloadOutline}/>
                        </div>
                        <IonButton
                            onClick={() => clickHandler(event, [PATIENT_FORM, PATIENT_SHORT_FORM, MEDICINE_FORM, CERTIFICATE_FORM])}
                            item-end
                            className={['ion-float-right', classes['button']].join(' ')}>Űrlapok
                            megtekintése</IonButton>
                        <IonButton onClick={() => clickHandler(event, [COVID_FORM])}
                                   item-end
                                   className={['ion-float-right', classes['button']].join(' ')}>Covid nyilatkozatok
                            megtekintése</IonButton>
                    </IonCol>
                </IonRow>
            </IonItem>
        );
    };

    return (
        <>
            <IonList className={classes['ion-list']}>
                {listEvents(events)}
            </IonList>
            <IonFab vertical="bottom" horizontal="end" className={classes['add-button']}>
                <IonFabButton color="success" onClick={createEvent}>
                    <IonIcon icon={add}/>
                </IonFabButton>
            </IonFab>
        </>
    );
};

export default EventList;
