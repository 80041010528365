import * as actionTypes from "../actions/actionTypes";

const initialState: any = [];

const setEvents = (state: any, action: any) => {
    return [...action.events];
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EVENTS:
            return setEvents(state, action);
        default:
            return state;
    }
};

export default reducer;
