import * as actionTypes from "./actionTypes";
import {EventInterface} from "../declarations";
import {clearStorage, getStorage, setStorage} from "../plugins/storage";
import * as storageTypes from "../plugins/storageTypes";
import {getImageDataURL} from "../../utils/functions/common";
import * as actions from "./index";
import axios from "axios";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import {routes} from "../../utils/consants/routes";

export const clearEvent = () => {
    return (dispatch: any) => {
        clearStorage(storageTypes.EVENT).then(() => {
            dispatch(removeEvent());
        });
    };
};

export const removeEvent = () => {
    return {
        type: actionTypes.EVENT_CLEAR
    };
};

export const updateEvent = (event: EventInterface) => {
    return (dispatch: any) => {
        return new Promise(async (resolve) => {
            if (event.logo && event.logo.path) {
                const logoPath = process.env.REACT_APP_API_BASE_URL + event.logo.path;
                const dataUrl = await getImageDataURL(logoPath);

                if (dataUrl) {
                    event.logo = dataUrl;
                }

                await setStorage(storageTypes.EVENT, event);
            }

            dispatch(setEvent(event));
            resolve();
        });
    };
};

export const setEvent = (event: EventInterface) => {
    return {
        type: actionTypes.SET_EVENT,
        event
    };
};

export const initEventFromStorage = () => {
    return (dispatch: any) => {
        return getStorage(storageTypes.EVENT).then((event: any) => {
            console.log('[actions/event.ts] initEventFromStorage done');
            dispatch(setEvent(event));
        });
    }
};

export const createEvent = (payload: any, history: any) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        dispatch(actions.showSpinnerModal());

        axios.post(apiEndpoints.API_EVENT_NEW, payload, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.eventList);
            })
            .catch((e) => {
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[event/new.ts] NEW_EVENT', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    };
};

export const updateEventOnServer = (payload: any, history: any) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        dispatch(actions.showSpinnerModal());

        axios.put(apiEndpoints.API_EVENT_UPDATE, payload, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Event/Edit.ts] API_EVENT_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.eventList);
            })
            .catch((e) => {
                console.error('[Event/Edit.ts] API_EVENT_UPDATE', e);
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[event/new.ts] UPDATE_EVENT', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    };
};
