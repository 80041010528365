import React from 'react';
import {IonButton, IonContent, IonPage, IonSpinner} from "@ionic/react";
import {SyncState} from "../../store/states";

const Sync: React.FC = () => {
    const {lastSyncDate, isSyncError, isSyncLoading, startSync} = SyncState();
    const syncButtonHandler = () => {
        startSync();
    };

    const getDate = lastSyncDate ? new Date(lastSyncDate).toLocaleString() : null;

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1 className="ion-padding-bottom">Szinkronizáció</h1>
                {getDate && <h5>Utolsó szinkronizáció dátuma: {getDate}</h5>}
                {isSyncLoading ? <IonSpinner/> : null}
                {isSyncError ? <p>A szinkronizáció sikertelen volt. Próbálja meg később.</p> : null}
                <IonButton onClick={syncButtonHandler}>Szinkronizáció indítása</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Sync;
