import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../utils/functions/common";

const initialState = {
    show: false,
    message: ''
};

const showToast = (state: any, action: any) => {
    return updateObject(state, {show: true, message: action.message});
};

const hideToast = (state: any, action: any) => {
    return updateObject(state, {show: false});
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TOAST_SHOW:
            return showToast(state, action);
        case actionTypes.TOAST_HIDE:
            return hideToast(state, action);
        default:
            return state;
    }
};

export default reducer;
