import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonCol,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonList,
    IonRow,
    useIonViewDidEnter,
    useIonViewDidLeave
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {add, pencilOutline, trash} from "ionicons/icons";

import {API_HOSPITAL_GET} from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import {routes} from "../../utils/consants/routes";
import * as actions from "../../store/actions";
import {HospitalInterface} from "../../store/declarations";
import Spinner from "../Spinner/Spinner";
import classes from "./HospitalList.module.scss";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";

interface HospitalListInterface extends RouteComponentProps {
    hospitals: HospitalInterface[];
}

const HospitalList: React.FC<HospitalListInterface> = ({hospitals}) => {
    const dispatch = useDispatch();
    const setHospitalsStore = (hospitals: HospitalInterface[]) => dispatch(actions.setHospitals(hospitals));
    const [isHospitalsLoaded, updateIsHospitalsLoaded] = useState(false);
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const payload = {};
    const history = useHistory();

    const getHospitals = () => {
        axios.post(API_HOSPITAL_GET, payload, config)
            .then((response) => {
                    setHospitalsStore(response.data.payload);
                    updateIsHospitalsLoaded(true);
                }
            )
    };

    useIonViewDidEnter(() => {
        dispatch(actions.removeHospital());
        getHospitals();
    });

    useIonViewDidLeave(() => {
        updateIsHospitalsLoaded(false);
    });

    const createHospital = () => {
        history.push(routes.hospitalNew)
    };

    const editURLWithID = (hospitalID: number) => {
        return routes.hospitalEdit.replace(":hospitalID", hospitalID.toString());
    };

    const editHospital = (hospitalID: number) => {
        history.push(editURLWithID(hospitalID))
    };

    const deleteHospital = (hospitalID: number) => {
        if (!window.confirm('Biztosan törölni szeretné?')) {
            return;
        }

        return axios.delete(apiEndpoints.API_HOSPITAL_DELETE + '/' + hospitalID, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Hospital/List.ts] API_HOSPITAL_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.go(0);
            })
            .catch((e) => {
                console.error('[Hospital/List.ts] API_HOSPITAL_UPDATE', e);
            });
    };

    const listHospitals = (hospitals: any) => {
        if (!isHospitalsLoaded || !hospitals) {
            return <Spinner/>;
        }

        if (hospitals.length === 0) {
            return <h1>Nincsenek klinikák</h1>;
        }

        return hospitals.map((hospital: any) => hospital.filtered ? null :
            <IonItem key={hospital.id} lines="none">
                <IonRow className={classes["content"]}>
                    <IonCol>
                        <h2 className={classes["content__title"]}>
                            {hospital.name}
                        </h2>

                        <p className={classes["content__description"]}>
                            {hospital.value}
                        </p>
                    </IonCol>

                    <IonCol className={classes["content__actions"]}>
                        <div className={classes["link"]} onClick={() => deleteHospital(hospital.id)}>
                            <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                     icon={trash}/>
                        </div>
                        <div className={classes["link"]} onClick={() => editHospital(hospital.id)}>
                            <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                     icon={pencilOutline}/>
                        </div>
                    </IonCol>
                </IonRow>
            </IonItem>
        );
    };

    return (
        <>
            <IonList className={classes['ion-list']}>
                {listHospitals(hospitals)}
            </IonList>
            <IonFab vertical="bottom" horizontal="end" className={classes['add-button']}>
                <IonFabButton color="success" onClick={createHospital}>
                    <IonIcon icon={add}/>
                </IonFabButton>
            </IonFab>
        </>
    );
};

export default HospitalList;
