import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";

const User = () => {
    const {assignRoles} = useSelector((state: any) => state.user);
    const userStore = useSelector((state: any) => state.user);

    const dispatch = useDispatch();
    const editUser = (editedProfile: any) => dispatch(actions.editUser(editedProfile));

    return {
        assignRoles,
        userStore,
        editUser
    }
};

export default User;
