import React from "react";
import classes from "./Footer.module.scss";
import {IonCol, IonRow} from "@ionic/react";

const Footer = () => {
    return (
        <IonRow className={classes["footer"]}>
            <IonCol>
                <p>All rights reserved</p>
            </IonCol>
            <IonCol>
                <p className={classes["footer__version"]}>Ver 1.11.1 {process.env.REACT_APP_ENV ?? null}</p>
            </IonCol>
        </IonRow>
    );
};

export default Footer;
