import covidConsent from "./1_covid";
import {FormInterface} from "../../../../declarations";
import {buttonExit, buttonLock, buttonLockAndEmail} from "../../../controllButtons/controllButtons";

const covid: FormInterface[] = [
    {
        form_name: "Covid nyilatkozat / Covid consent",
        form_data: covidConsent,
        buttons: [
            buttonExit,
            buttonLock,
            buttonLockAndEmail
        ]
    },
];

export default covid;