import {CameraPhoto, FilesystemDirectory} from "@capacitor/core";
import {base64FromPath} from "@ionic/react-hooks/filesystem";

export const loadSavedPhoto = async (value: any, readFile: any) => {
    console.log('[functions/photo.ts] loadSavedPhoto');
    const file = await readFile({
        path: value,
        directory: FilesystemDirectory.Data
    });

    return `data:image/jpeg;base64,${file.data}`;
};

export const SavePicture = async (photo: CameraPhoto, writeFile: any) => {
    const base64Data = await base64FromPath(photo.webPath!);
    const fileName = new Date().getTime() + '.jpeg';

    await writeFile({
        path: fileName,
        data: base64Data,
        directory: FilesystemDirectory.Data
    });

    return {
        fileName: fileName,
        base64Data: base64Data
    };
};

export const imageToBase64 = (file: File) => new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
