import React, {useCallback, useEffect} from 'react';
import {IonContent, IonPage} from "@ionic/react";
import {RouteComponentProps, useHistory} from "react-router";

import form from "../../../store/static/forms/profile/profile";
import Spinner from "../../../components/Spinner/Spinner";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import {FormInterface} from "../../../store/declarations";
import _ from "lodash";
import {ActiveFormState, AlertState, UserState} from "../../../store/states";

const UserEdit: React.FC<RouteComponentProps> = (props) => {
    const {updateActiveForm, activeForm, inputHandler} = ActiveFormState();
    const {editUser, userStore} = UserState();
    const {showSuccessAlert} = AlertState();

    let history = useHistory();

    const init = useCallback(() => {
        const userClone = _.cloneDeep(userStore);
        const newForm = _.cloneDeep(form);
        const formElementsArray = initForm(newForm, userClone);

        updateActiveForm(formElementsArray);
    }, [updateActiveForm, userStore]);

    useEffect(() => {
        if (userStore.name) {
            init();
        }
    }, [userStore, init]);

    const initForm = (form: FormInterface, user: any): FormInterface => {
        Object.keys(user).forEach((key) => {
            if (form.form_data[key] !== undefined) {
                form.form_data[key].value = user[key]
            }
        });

        return form;
    };

    const save = () => {
        if (editUser(activeForm)) {
            showSuccessAlert('A profil sikeresen módosult.')
            history.push("/charts");
        }
    };

    const exit = () => {
        history.goBack();
    };

    const drawForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={{
                save,
                exit,
            }}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>User</h1>
                {drawForm()}
            </IonContent>
        </IonPage>
    );
};

export default UserEdit;
