import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";

const Toast = () => {
    const dispatch = useDispatch();
    const showToast = (message: string) => dispatch(actions.showToast(message));

    return {
        showToast
    }
};

export default Toast;
