import * as actions from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useFilesystem} from "@ionic/react-hooks/filesystem";

const Sync = () => {
    const {readFile} = useFilesystem();

    const isSyncError: string = useSelector((state: any) => state.sync.error);
    const isSyncLoading: boolean = useSelector((state: any) => state.sync.loading);
    const lastSyncDate: number = useSelector((state: any) => state.sync.lastSyncDate);

    const dispatch = useDispatch();
    const startSync = () => dispatch(actions.startTwoWaySync(readFile));

    return {
        isSyncError,
        isSyncLoading,
        lastSyncDate,
        startSync,
    }
};

export default Sync;
