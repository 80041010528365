import medical_certificate from "./1_certificate";
import {FormInterface} from "../../../../declarations";
import {buttonExit, buttonLock, buttonLockAndEmail} from "../../../controllButtons/controllButtons";

const certificate: FormInterface[] = [
    {
        form_name: "Orvosi igazolás / Medical certificate",
        form_data: medical_certificate,
        buttons: [
            buttonExit,
            buttonLock,
            buttonLockAndEmail
        ]
    },
];

export default certificate;