import React, {useCallback, useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonPage,
    useIonViewDidEnter,
    useIonViewDidLeave,
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch} from "react-redux";

import Header from "../../../components/Header/Header";
import {ChartInterface, InputFieldInterface, LooseObject, PdfButtonInterface} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import {getChartByHash} from "../../../utils/functions/chart";
import classes from "./Pdf.module.scss";
import * as actions from "../../../store/actions";
import {ChartState} from "../../../store/states";
import {routes} from "../../../utils/consants/routes";
import {pdfButtons} from "../../../store/static/pdfButtons/pdfButtons";
import {COVID_FORM} from "../../../utils/functions/constants";

interface PdfProps extends RouteComponentProps<{
    chartHash: string;
}> {
}

const Pdf: React.FC<PdfProps> = (props) => {
    const {charts, isChartsLoading} = ChartState();
    const dispatch = useDispatch();
    const getPdfByType = (pdfType: string[]) => dispatch(actions.getPdfByType(pdfType, match.params.chartHash));
    const {match, history} = props;
    const getCurrentChart = useCallback(() => getChartByHash(charts, match.params.chartHash), [charts, match]);
    const [loaded, updateLoaded] = useState(false);
    const [fields, updateFields] = useState<LooseObject>({});
    const [chart, updateChart] = useState<ChartInterface | undefined>();
    const [homeRouteSuffix, setHomeRouteSuffix] = useState(routes.homeDefault);

    useIonViewDidEnter(() => {
        updateLoaded(false);
        setTimeout(() => {
            updateLoaded(true);
        }, 1);
    });

    const initChart = useCallback(() => {
        const chart = getCurrentChart();

        if (!chart || chart.forms.length === 0) {
            history.push('/');
            return;
        }

        if (chart.forms[0].type === COVID_FORM) {
            setHomeRouteSuffix(routes.homeCovid);
        }
        else {
            setHomeRouteSuffix(routes.homeDefault);
        }

        updateChart(chart);
        updateFields(setFieldObject(chart));
    }, [getCurrentChart, history]);

    const setFieldObject = (chart: ChartInterface) => {
        let fields: LooseObject = {};

        chart.forms[0].input_fields.forEach((field: InputFieldInterface) => {
            fields[field.name] = field.value;
        });

        return fields;
    };

    useEffect(() => {
        if (!isChartsLoading) {
            initChart();
        }
    }, [initChart, isChartsLoading]);

    useIonViewDidLeave(() => {
        updateLoaded(false);
    });

    const openFile = (pdfType: string) => {
        getPdfByType([pdfType]);
    };

    const showTemplate = () => {
        if (!loaded || isChartsLoading) {
            return <Spinner/>
        }

        return (
            <div className="ion-padding">
                <h1 className="ion-padding-bottom">Páciens neve: {fields.last_name} {fields.first_name}</h1>

                <div className={classes['button-group']}>
                {!!chart && pdfButtons[chart.forms[0].type].map((pdfButton: PdfButtonInterface, index: number) => (
                    <IonButton key={index} onClick={() => openFile(pdfButton.fileName)}>
                        {pdfButton.text}
                    </IonButton>
                ))}
                </div>

                <div className="ion-margin-top">
                    <IonButton
                        onClick={() => history.push(routes.userHome + homeRouteSuffix)}
                        color="danger"
                    >
                        Vissza a főoldalra
                    </IonButton>
                </div>
            </div>
        );
    };

    return (
        <IonPage>
            <IonContent>
                <Header/>

                <div className="ion-padding">
                    {showTemplate()}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Pdf, (prevProps, nextProps) => prevProps.match.params.chartHash === nextProps.match.params.chartHash);
