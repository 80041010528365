import {EventInterface} from "../../declarations";

const contents = [
    {
        id: 'privacy_button',
        title: 'Adatvédelmi nyilatkozat / Privacy statement',
        text: () => (`
            <h2>Privacy Statement of Consent and Information</h2>
            <p>We would like to inform you that the processing of your medical data is carried out in accordance with Act XLVII of 1997 on the Processing and Protection of Health and Related Personal Data in order to promote the preservation, improvement and maintenance of health and the effective treatment of patients by the healthcare provider. The use of our services is based on a voluntary decision, if you use them, you also consent to the processing of your data by the service provider. If you do not consent to the processing of your data, please inform our colleagues. The possible consequence of the lack of consent is that the Data Controller is unable to provide a full service. You can read our data management policy on our website: www.semmelweiskft.hu.<br>
            <br>
            I acknowledge that during my care in order to protect my health, an active substance or procedure may be required that is subject to TUE authorization under the applicable WADA Prohibited List. In this case, I am aware of my administrative obligation for the retroactive TUE.
            </p>

            <hr/>

            <h2>Adatvédelmi hozzájárulási nyilatkozat és tájékoztató</h2>
            <p>Tájékoztatjuk, hogy egészségügyi adatainak kezelése az egészségügyi és a hozzájuk kapcsolódó személyes adatok kezeléséről és védelméről szóló 1997. évi XLVII. törvény alapján az egészség megőrzésének, javításának, fenntartásának előmozdítása, illetve a betegellátó eredményes gyógykezelési tevékenységének elősegítése érdekben történik. Szolgáltatásunk igénybevétele önkéntes elhatározáson alapul, ezzel adatainak kezeléséhez hozzájárult. Amennyiben az adatkezeléshez nem járul hozzá, azt kérjük jelezze kollégáinknak. A hozzájárulás hiányának lehetséges következménye: az Adatkezelő nem tud teljes körű szolgáltatást nyújtani. Adatkezelési szabályzatunkat a www.semmelweiskft.hu honlapon ismerheti meg.<br>
            <br>
            Tudomásul veszem, hogy az egészségem védelme érdekében az ellátásom során szükség lehet olyan hatóanyagra vagy eljárásra, mely a hatályos sportolói WADA tiltólista alapján TUE engedélyköteles. Ez esetben az adminisztrációs kötelezettségemnek tudatában vagyok.
            </p>
        `)
    },
    {
        id: 'payment_button',
        title: 'Biztosítási feltételek / Insurance policy',
        text: (event: EventInterface) => event.insurance_text + '<hr/>' + event.insurance_text_en
    },
    {
        id: 'covid_button',
        title: 'Covid ügyfélnyilatkozat / Covid statement',
        text: (event: EventInterface) => event.covid_text
    }
];

export default contents;
