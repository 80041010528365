export const emptyInputFields = [
    {
        name: 'last_name',
        value: 'Empty'
    },
    {
        name: 'first_name',
        value: 'Empty'
    },
    {
        name: 'date_of_birth',
        value: 'Empty'
    }
];
