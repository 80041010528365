import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../utils/functions/common";
import {AuthInterface} from "../declarations/auth";

const initialState: AuthInterface = {
    token: null,
    expirationDate: "",
    error: null,
    loading: true,
    authRedirectPath: "/"
};

const authLoadingStart = (state: any, action: any) => {
    return updateObject(state, {error: null, loading: true});
};

const authLoadingEnd = (state: any, action: any) => {
    return updateObject(state, {loading: false});
};

const authSuccess = (state: any, action: any) => {
    return updateObject(state, {
        token: action.idToken,
        expirationDate: action.expirationDate,
        error: null
    });
};

const authFail = (state: any, action: any) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state: any, action: any) => {
    return updateObject(state, {token: null, loading: false});
};

const setAuthRedirectPath = (state: any, action: any) => {
    return updateObject(state, {authRedirectPath: action.path});
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AUTH_START_LOADING:
            return authLoadingStart(state, action);
        case actionTypes.AUTH_LOADING_END:
            return authLoadingEnd(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.AUTH_SET_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        default:
            return state;
    }
};

export default reducer;
