import React from 'react';

import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";
import {role} from "../../utils/consants/auth";
import {isUserHasAccess} from "../../utils/functions/auth";
import {routes} from "../../utils/consants/routes";

type ProtectedRouteProps = {
    component?: React.Component | React.FunctionComponent | React.ReactNode;
    path: string;
    exact?: boolean;
    render?: any;
    roles?: string[];
};

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = (props) => {
    const {component: Component, render, path, roles, ...rest} = props;
    const isAuthenticated = useSelector((state: any) => state.auth.token || null);
    const {assignRoles} = useSelector((state: any) => state.user);
    const isHasAccess = isUserHasAccess(roles, assignRoles);

    let renderFunction: any = (props: any) => {
        if (!isAuthenticated) {
            return <Redirect to={routes.login}/>;
        }

        if (!isHasAccess) {
            if (assignRoles.indexOf(role.admin) > -1) {
                return <Redirect to={routes.adminHome}/>;
            }

            return <Redirect to={routes.userHome + routes.homeDefault}/>;
        }

        // @ts-ignore
        return <Component {...props} />;
    };

    if (render && isAuthenticated && isHasAccess) {
        renderFunction = render;
    }

    return (
        <Route
            path={path}
            {...rest}
            render={renderFunction}
        />
    );
};

export default ProtectedRoute;
