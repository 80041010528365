import {FormDataInterface} from "../../../../declarations";
import {drug_allergies, home_medications, relevant_history} from "../../../inputFields/inputFields";

const drug_allergies_history: FormDataInterface = {
    drug_allergies,
    relevant_history,
    home_medications
};

export default drug_allergies_history;
