import React from 'react';
import {
    IonButton,
    IonCheckbox,
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import {add, calendar, trash} from "ionicons/icons";

import Signature from "../Signature/Signature";
import Photo from "../Photo/Photo";
import {ButtonHandlerInterface, FormConfigInterface} from "../../../store/declarations";
import classes from './Input.module.scss';
import Translate from "../Translate/Translate";
import {DATE_FORMAT} from "../../../utils/consants/common";
import TextEditor from "../TextEditor/TextEditor";
import SearchFilter from "../SearchFilter/SearchFilter";

interface PropsInterface extends FormConfigInterface {
    inputName?: string;
    buttonHandlers?: ButtonHandlerInterface[];
    minDateType?: string;
    maxDateType?: string;
    isActiveFormLocked: boolean;
}

const Input: React.FC<PropsInterface> = (props) => {
    const {
        inputName,
        value,
        base64Data,
        elementType,
        elementConfig,
        changed,
        helper,
        label,
        touched,
        valid,
        text,
        buttonHandlers,
        isActiveFormLocked,
    } = props;

    let inputElement = null;
    let position: "floating" | "fixed" | "stacked" | undefined = "stacked";
    let minDateType = 'past';
    let maxDateType = 'future';
    const inputDebounce = 1000;

    const inputClasses: any = ['ion-no-padding'];
    let inputItemClasses = [classes['input-item']];

    if (!valid && touched) {
        inputClasses.push(classes.invalid);
    }

    if (isActiveFormLocked) {
        inputItemClasses.push(classes['input-item--disabled']);
    }

    if (elementConfig.classes && classes[elementConfig.classes]) {
        inputItemClasses.push(classes[elementConfig.classes]);
    }

    switch (elementType) {
        case ('text'):
            if (text) {
                inputElement = <div dangerouslySetInnerHTML={{__html: text}}/>;
            }
            break;
        case ('button'):
            let buttonHandler = (e: any, name: string): any => {
            };

            if (buttonHandlers) {
                const foundHandler = buttonHandlers.find((handler) => {
                    return (handler.inputName === inputName || handler.inputName.indexOf(inputName as string) >= 0 || handler.inputName === inputName?.substr(0, inputName?.indexOf('_')));
                });

                if (!foundHandler) {
                    inputElement = null;
                    break;
                }

                buttonHandler = foundHandler.handler;
            }

            let colorProp: any = {};
            if (elementConfig.buttonColor) {
                colorProp.color = elementConfig.buttonColor;
            }

            const getIcon = (iconName: string) => {
                switch (iconName) {
                    case 'add':
                        return add;
                    case 'remove':
                        return trash;
                    default:
                        return add;
                }
            };

            if (elementConfig.type && elementConfig.type === 'text' && text) {
                inputElement = (
                    <IonLabel onClick={(e) => buttonHandler(e, inputName || '')} className={classes.Label}
                              position={position}><span
                        dangerouslySetInnerHTML={{__html: text}}/></IonLabel>
                );
            } else {
                inputElement = (
                    <IonButton
                        {...colorProp}
                        onClick={(e) => {
                            console.log('click', buttonHandler);
                            buttonHandler(e, inputName || '');
                        }}>
                        {elementConfig.buttonIcon ? <IonIcon icon={getIcon(elementConfig.buttonIcon)}/> : text}
                    </IonButton>
                );
            }

            break;
        case ('input'):
            inputElement = (
                elementConfig.translated ?
                    <>
                        <IonInput
                            {...elementConfig}
                            value={value}
                            disabled={isActiveFormLocked}
                            onIonInput={changed}
                            debounce={inputDebounce}
                            {...props}
                        />
                        <Translate textToTranslate={value}/>
                    </> :
                    <IonInput
                        {...elementConfig}
                        value={value}
                        disabled={isActiveFormLocked}
                        onIonInput={changed}
                        debounce={inputDebounce}
                        {...props}
                    />
            );
            break;
        case ('checkbox'):
            position = undefined;
            const isChecked = value === '1';
            inputElement = (
                <IonCheckbox
                    {...elementConfig}
                    disabled={isActiveFormLocked}
                    value={value}
                    checked={isChecked}
                    onIonChange={changed}
                    slot="start"
                    {...props}
                />
            );
            break;
        case ('radio'):
            inputElement = (
                elementConfig && elementConfig.options ? elementConfig.options.map((option: any) => (
                    <IonItem key={option.optionValue}>
                        <IonLabel>{option.displayName}</IonLabel>
                        <IonRadio
                            slot="start"
                            value={option.optionValue}
                            disabled={isActiveFormLocked}
                        />
                    </IonItem>
                )) : null
            );
            break;
        case ('date'):
            position = undefined;
            minDateType = elementConfig.minDateType || minDateType;
            maxDateType = elementConfig.maxDateType || maxDateType;
            const d = new Date();
            const dateString: any = {
                'past': '1900-01-01',
                'present': d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2),
                'future': (d.getFullYear() + 1) + '-12-31'
            };
            inputElement = (
                <>
                    <IonIcon icon={calendar} slot="start"/>
                    <IonDatetime
                        {...elementConfig}
                        displayFormat={DATE_FORMAT}
                        value={value}
                        onIonChange={changed}
                        cancelText="Mégse"
                        doneText="OK"
                        slot="start"
                        disabled={isActiveFormLocked}
                        min={dateString[minDateType]}
                        max={dateString[maxDateType]}
                        {...props}
                    />
                </>
            );
            break;
        case ('textarea'):
            inputElement = (
                elementConfig.translated ? <>
                    <IonTextarea
                        {...elementConfig}
                        value={value}
                        onIonChange={changed}
                        disabled={isActiveFormLocked}
                        autocapitalize={'sentences'}
                        debounce={inputDebounce}
                        {...props}
                    />
                    <Translate textToTranslate={value}/>
                </> : <IonTextarea
                    {...elementConfig}
                    value={value}
                    onIonChange={changed}
                    disabled={isActiveFormLocked}
                    autocapitalize={'sentences'}
                    debounce={inputDebounce}
                    {...props}
                />
            );
            break;
        case ('signature'):
            inputElement = (
                <Signature
                    changed={changed}
                    value={value}
                    disabled={isActiveFormLocked}
                    {...props}
                />
            );
            break;
        case ('selectFilter'):
            if (elementConfig) {
                inputElement = (
                    <SearchFilter
                        value={value}
                        nationArray={elementConfig.options}
                        {...props}
                    />
                );
            }
            break;
        case ('select'):
            if (elementConfig) {
                inputElement = (
                    <IonSelect
                        value={value}
                        onIonChange={changed}
                        multiple={elementConfig.multiple}
                        cancelText="Mégse"
                        okText="OK"
                        disabled={isActiveFormLocked}
                        {...props}
                    >
                        {elementConfig && elementConfig.options ? elementConfig.options.map((option: any, key) => (
                            <IonSelectOption key={key} value={option.optionValue}>
                                {option.displayName}
                            </IonSelectOption>
                        )) : null}
                    </IonSelect>
                );
            }
            break;
        case ('picture'):
        case ('image'):
            position = undefined;
            inputElement = (
                <Photo
                    base64Data={base64Data ?? ''}
                    elementType={elementType}
                    inputName={inputName}
                    disabled={isActiveFormLocked}
                    {...props}
                />
            );
            break;
        case ('texteditor'):
            inputItemClasses.push(classes['overflow-visible']);
            inputElement = (<TextEditor
                    value={value}
                    changed={changed}
                />
            );
            break;
        default:
            inputElement = (
                <IonInput
                    {...elementConfig}
                    value={value}
                    onIonChange={changed}
                    debounce={inputDebounce}
                    {...props}
                />
            );
    }

    const helperText = helper && (<div><small dangerouslySetInnerHTML={{__html: helper}}/></div>);

    return (
        <div className={inputItemClasses.join(' ')}>
            {elementType === 'radio' ?
                <IonRadioGroup value={value} onIonChange={changed}>
                <IonLabel className={inputClasses.join(' ')}>
                        <span dangerouslySetInnerHTML={{__html: label}}/>{helper}
                    </IonLabel>
                    {inputElement}
                </IonRadioGroup>
                : <IonItem className={inputClasses.join(' ')}>
                    {(label && !elementConfig.hideLabel) &&
                        <IonLabel className={classes.Label} position={position}><span
                            dangerouslySetInnerHTML={{__html: label}}/>{helperText}</IonLabel>}
                    {inputElement}
                </IonItem>
            }
        </div>
    );
};

export default React.memo(Input);
