import React from "react";
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import popupContents from '../../../store/static/popupTexts';
import {EventState} from "../../../store/states";
import './Popup.scss';

interface ContentObject {
    id: string;
    replaceObj: any
}

interface PopupProps {
    showModal: boolean,
    setShowModal: any,
    contentObj: ContentObject
}

const popup: React.FC<PopupProps> = ({showModal, setShowModal, contentObj}) => {
    const {event} = EventState();
    const content = popupContents.find(content => content.id === contentObj.id);

    return (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>{content!.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-padding ion-text-justify" color="light">
                <div dangerouslySetInnerHTML={{__html: content?.text(event) ?? ''}}/>
            </IonContent>
        </IonModal>
    );
};

export default popup;
