import React, {useState} from 'react';
import {IonInput} from "@ionic/react";
import classes from "./SearchFilter.module.scss";

const SearchFilter = (props: any) => {
    const [filterInput, setFilterInput] = useState(props.value);
    const [selected, setSelected] = useState(false);
    const [nationArray] = useState(props.nationArray);


    const onChange = (e: any) => {
        let filterText = e.target.value;
        setFilterInput(filterText);
        props.changed(e);
        if (filterText === filterInput) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }

    const Results = () => (
        <div className={classes["filterDropdown"]}>
            {filterInput && nationArray.filter((country: any) => {
                return country.displayName.toLowerCase().includes(filterInput.toLowerCase());
            }).map((x: any) =>
                <div onClick={() => {
                    setFilterInput(x.optionValue);
                }} key={x.optionValue}>{x.displayName}</div>
            )}
        </div>
    );

    return (
        <>
            <IonInput type="text" value={filterInput}
                      onIonChange={onChange}/>
            {filterInput && !selected ? <Results/> : null}
        </>
    );
};

export default SearchFilter;