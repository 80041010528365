import React, {useCallback, useEffect, useState} from 'react';
import {
    IonContent, IonItem, IonLabel,
    IonPage, useIonViewDidEnter, useIonViewDidLeave,
} from "@ionic/react";
import {initStatistics} from "../../utils/functions/statistics";
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions";
import {getAxiosConfigWithToken} from "../../utils/functions/http";
import classes from "./Statistics.module.scss";
import Spinner from "../../components/Spinner/Spinner";
import {AuthState} from "../../store/states";

const Statistics: React.FC = () => {
    const {token} = AuthState();
    const dispatch = useDispatch();
    const axiosConfig = useCallback(() => getAxiosConfigWithToken(token), [token]);
    const [statistics, updateStatistics] = useState<any>(undefined);
    const [needsUpdate, setNeedsUpdate] = useState(true);
    const initStat = useCallback(() => initStatistics(dispatch, axiosConfig()), [dispatch, axiosConfig]);

    const init = useCallback(() => {
        initStat().then((response: any) => {
            if (response.data.success !== true) {
                console.error('[Statistics.tsx] initStatistics fail', response);
                dispatch(actions.showToast('Hiba történt az adatok lekérésében!'));
                return;
            }

            updateStatistics(response.data.payload.events);
        });
    }, [dispatch, initStat]);

    useEffect(() => {
        if (needsUpdate && statistics === undefined) {
            init();
        }
    }, [init, needsUpdate, statistics]);

    useIonViewDidEnter(() => {
        setNeedsUpdate((prevState => !prevState));
    });

    useIonViewDidLeave(() => {
        setNeedsUpdate(false);
        updateStatistics(undefined);
    });

    const drawCategories = (categories: any[]) => {
        return categories.map(category => {
            if (category.data.length > 0) {
                return (
                    <IonLabel key={category.name}>
                        <strong className={classes['statistics__category-label']}>{category.name}:</strong><br/>
                        <table className={classes['statistics__category-table']}>
                            <thead>
                            <tr>
                                <td>Érték</td>
                                <td>Mennyiség</td>
                                <td>Arány</td>
                            </tr>
                            </thead>
                            <tbody>
                            {category.data.map((data: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.value}</td>
                                        <td>{data.count}</td>
                                        <td>{data.percent}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </IonLabel>
                );
            }
            return null;
        })
    };

    const drawStatistics = () => {
        if (!statistics) {
            return <Spinner/>;
        }

        return (
            <>
                {statistics.map((event: any) => {
                    return (
                        <IonItem key={event.name}>
                            <IonLabel>
                                Esemény: {event.name} <br/>
                                Összes ellátott személy: {event.total || 0}<br/>
                                Ebből csak gyógyszerkiadás: {event.total_drug_only || 0}<br/>
                                Ebből csak orvosi igazolás: {event.total_medical_certificate}<br/>
                                Ebből klinikai ellátás: {event.total_on_site || 0}<br/>
                                Összes vizsgálat: {event.total_examination || 0}<br/>
                                Összes Covid teszt: {event.total_covid}<br/><br/>
                                {drawCategories(event.category)}
                            </IonLabel>
                        </IonItem>
                    );
                })}
            </>
        );
    };

    return (
        <IonPage>
            <IonContent className={`ion-padding ${classes.statistics}`}>
                <h1 className="ion-padding-bottom">Statisztikák</h1>
                <br/>
                {drawStatistics()}
            </IonContent>
        </IonPage>
    );
};

export default Statistics;
