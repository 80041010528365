import {FormDataInterface} from "../../../../declarations";
import {accreditation_no, accreditation_type, insurance_no, sports} from "../../../inputFields/inputFields";

const accreditation: FormDataInterface = {
    //insurance_no,
    accreditation_no,
    accreditation_type,
    sports
};

export default accreditation;
