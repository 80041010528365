import React, {useCallback} from 'react';
import {IonContent, IonPage, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {useHistory} from "react-router";
import _ from "lodash";
import axios from "axios";

import {routes} from "../../../utils/consants/routes";
import * as apiEndpoints from "../../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../../utils/functions/http";
import Spinner from "../../../components/Spinner/Spinner";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import form from "../../../store/static/forms/hospital/hospital"
import {ActiveFormState, AuthState, HospitalState, ToastState} from "../../../store/states";

const Edit: React.FC = (props: any) => {
    const {updateActiveForm, activeForm, validateForm, doSave, resetActiveForm, inputHandler} = ActiveFormState();
    const {updateHospital} = HospitalState();
    const {token} = AuthState();
    const {showToast} = ToastState();


    const history = useHistory();
    const {match} = props;

    const getHospitalID = () => history.location.pathname.split('/').slice(-1).toString();

    let hospitalID = getHospitalID();

    const initForm = useCallback(async () => {
        const config = getAxiosConfigWithToken(token);
        const newForm = _.cloneDeep(form);
        const payload = {
            id: hospitalID
        };
        console.log('[Hospitals/Edit/Edit.ts] initForm');

        axios.post(apiEndpoints.API_HOSPITAL_SHOW, payload, config)
            .then((response: any) => {
                if (response.data.success !== true) {
                    console.error('[Hospitals/Edit/Edit.ts] API_HOSPITAL_SHOW', response);
                    showToast(getErrorMessageFromResponse(response));
                    return;
                }

                const {payload} = response.data;
                newForm.form_data['name'].value = payload.name;
                newForm.form_data['value'].value = payload.value;

                validateForm(newForm);
                updateActiveForm(newForm);
            })
            .catch((e) => {
                console.error('[Hospitals/Edit/Edit.ts] API_HOSPITAL_SHOW', e);
                showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.')
            });


    }, [hospitalID, showToast, token, updateActiveForm, validateForm]);

    useIonViewDidEnter(() => {
        hospitalID = getHospitalID();
        initForm();
    }, [match.params.id]);

    useIonViewWillLeave(() => resetActiveForm());

    const save = () => {
        doSave(() => {
            const payload = {
                hospital: {
                    name: activeForm.form_data.name.value,
                    value: activeForm.form_data.value.value,
                    id: parseInt(hospitalID)
                }
            };
            updateHospital(payload, history);
        });
    };

    const exit = () => {
        props.history.push(routes.hospitalList)
    };

    const drawForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={{
                save,
                exit,
            }}

            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Klinika szerkesztése</h1>

                {drawForm()}
            </IonContent>
        </IonPage>
    );
};

export default Edit;
