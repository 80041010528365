export const routes = {
    adminHome: '/admin',
    statistics: '/statistics',
    userHome: '/charts',
    userHomeExtended: '/charts/:chartListType',
    login: '/login',
    sync: '/sync',
    userEdit: '/user/edit',
    chartEmail: '/charts/:chartHash/email',
    chartPdf: '/charts/:chartHash/pdf',
    chartEdit: '/charts/:chartHash/:formType/:formHash/:pageIndex',
    eventList: '/events',
    eventNew: '/events/new',
    eventEdit: '/events/edit/:eventID',
    hospitalList: '/hospitals',
    hospitalNew: '/hospitals/new',
    hospitalEdit: '/hospitals/edit/:hospitalID',
    homeDefault: '/default',
    homeCovid: '/covid'
};
