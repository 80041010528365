import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {
    IonContent,
    IonPage,
    IonProgressBar,
    useIonViewDidEnter,
    useIonViewWillLeave
} from "@ionic/react";
import _ from "lodash";
import {uuid} from "uuidv4";
// @ts-ignore
import swal from '@sweetalert/with-react';
import './Edit.scss';

import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import Header from "../../../components/Header/Header";

import forms from "../../../store/static/forms/chart";
import {
    initFormValues, onRowDeleteButtonClick,
    setDisplayOfDynamicInputFieldsAndControllers,
} from "../../../utils/functions/form";
import {
    getChartByHash,
    getChartFormById, getMainFormByChartHash,
    getPatientFormHash, getPdfTypesForCurrentChartType, initNewChart,
    navigationToChardEditPage
} from "../../../utils/functions/chart";
import {ButtonHandlerInterface, ChartFormInterface, ChartInterface} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import {
    COVID_FORM,
    EXAMINATION_FORM,
    MEDICINE_FORM,
    PATIENT_FORM,
    PATIENT_SHORT_FORM
} from "../../../utils/functions/constants";
import {FIRST_FORM_PAGE_INDEX} from "../../../utils/consants/charts";
import {isUserHasAccess} from "../../../utils/functions/auth";
import {role} from "../../../utils/consants/auth";
import Popup from '../../../components/UI/Popup/Popup';
import {routes} from "../../../utils/consants/routes";
import {FormTypes} from "../../../store/types";
import {
    ActiveFormState,
    AuthState,
    ChartState,
    EventState,
    SpinnerModalState,
    ToastState,
    UserState
} from "../../../store/states";
import {useFilesystem} from "@ionic/react-hooks/filesystem";
import {Plugins, Capacitor} from "@capacitor/core";
import {getAxiosConfigWithToken} from "../../../utils/functions/http";
import {sendEmail} from "../../../utils/functions/email";
import {showSuccessAlert} from "../../../store/actions";
import care_type from "../../../store/static/forms/chart/examination/4_type_of_care";

interface EditProps extends RouteComponentProps<{
    chartHash: string;
    formType: FormTypes;
    formHash: any;
    pageIndex: string;
    chartListType: string;
}> {
}

const Edit: React.FC<EditProps> = (props) => {
    const {
        setFormValidity,
        updateActiveForm,
        touchFormData,
        activeForm,
        activeFormIsValid,
        resetActiveForm,
        hideDynamicRow,
        displayNextElementOfDynamicInputs,
        inputHandler,
        addNewRowOfDynamicInput,
        deleteDynamicLastRow
    } = ActiveFormState();
    const {
        syncCharts,
        createNewFormForChart,
        setChartFormLocked,
        saveChart,
        charts,
        isChartsLoading,
        deleteEmptyChart,
        deleteEmptyForm,
        createNewChart
    } = ChartState();
    const {event} = EventState();
    const {assignRoles} = UserState();
    const {token} = AuthState();
    const {hideSpinnerModal, setSpinnerMessage} = SpinnerModalState();
    const {showToast} = ToastState();

    const contentRef = useRef(null);
    const {readFile, writeFile} = useFilesystem();

    const {match, history} = props;
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({id: '', replaceObj: {}});
    const [isActiveFormLocked, updateActiveFormIsLocked] = useState(false);
    const [disableSave, updateDisableSave] = useState(false);

    const {params} = match;
    const {formType, formHash, chartHash} = params;
    const pageIndex = +params.pageIndex;
    const currentForm = forms[formType];
    let progressBarPercent = (100 / (currentForm.length / (pageIndex))) / 100 || 0;
    const getCurrentChart = useCallback(() => getChartByHash(charts, chartHash), [charts, chartHash]);
    const isChartHasSpecificForm = (chart: ChartInterface, type: FormTypes) => chart.forms.find(form => form.type === type);

    const clearEmptyChart = useCallback(async () => {
        const mainForm = getMainFormByChartHash(charts, chartHash);
        if (mainForm.locked) {
            await deleteEmptyForm(chartHash, formHash);
        } else {
            await deleteEmptyChart(chartHash);
        }
        const routeSuffix = mainForm.type === COVID_FORM ? routes.homeCovid : routes.homeDefault;
        history.push(routes.userHome + routeSuffix);
    }, [chartHash, deleteEmptyChart, history, charts, deleteEmptyForm, formHash]);

    useEffect(() => {
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
                if (pageIndex === FIRST_FORM_PAGE_INDEX) {
                    clearEmptyChart();
                    return;
                }
                history.goBack();
            });
        }
    }, [clearEmptyChart, history, pageIndex]);

    useIonViewDidEnter(async () => {
        scrollToTop();
        if (!isChartsLoading) {
            await initCurrentChart();
        }
    }, [match.params]);

    useIonViewWillLeave(async () => {
        resetActiveForm();
        await deleteEmptyChart(chartHash);
    }, [match.params]);

    const initForm = useCallback(async (chartForm: ChartFormInterface, formPageIndex: number) => {
        console.log('[Edit.tsx] initForm');
        let newForm = _.cloneDeep(currentForm[formPageIndex]);
        let values = chartForm?.input_fields ?? [];
        const fileSystem = {readFile, writeFile};
        await initFormValues(newForm, values, event, fileSystem);
        if (newForm.form_data.admission_to) {
            let filteredAdmissions;
            let careType = values.filter(inputValue => inputValue.name === 'type_of_care');
            if (careType.length === 1) {
                if (careType[0].value.substr(0, 1) < 'D') {
                    filteredAdmissions = newForm.form_data.admission_to.elementConfig.options.filter((option: { optionValue: string; }) => {
                        let notFound = true;
                        event.hospitals.forEach((element: { value: any; }) => {
                            if (notFound) notFound = !(element.value === option.optionValue);
                        });
                        return notFound;
                    });
                    newForm.form_data.admission_to.elementConfig.options = filteredAdmissions;
                    updateActiveForm(newForm);
                    let prevAdmissionValue = values.filter(value => value.name === 'admission_to');
                    let newAdmissionValue;
                    if (prevAdmissionValue?.length === 1) {
                        newAdmissionValue = newForm.form_data.admission_to.elementConfig.options.filter((option: { optionValue: string }) => {
                            return option.optionValue === prevAdmissionValue[0].value;
                        });
                    }
                    let newValues = values.filter(value => value.name !== 'admission_to');
                    if (newAdmissionValue?.length > 0) {
                        newValues.push({name: 'admission_to', value: newAdmissionValue[0].optionValue, saved: true});
                    } else {
                        newForm.form_data.admission_to.valid = false;
                        newForm.form_data.admission_to.value = "";
                    }
                    chartForm.input_fields = newValues;
                }
            }
        }
        setFormValidity(newForm);
        setDisplayOfDynamicInputFieldsAndControllers(newForm);
        updateActiveFormIsLocked(chartForm.locked || isUserHasAccess([role.admin], assignRoles));
        updateActiveForm(newForm);
        updateDisableSave(false);
    }, [currentForm, readFile, writeFile, event, setFormValidity, updateActiveFormIsLocked, updateActiveForm, assignRoles]);

    const initCurrentChart = useCallback(async () => {
        const formPageIndex = pageIndex - 1;
        const chart = getCurrentChart();

        if (chart !== null && currentForm[formPageIndex]) {
            const chartForm = getChartFormById(chart, formHash);

            if (!chartForm || chartForm.type !== formType) {
                history.goBack();
                return;
            }

            await initForm(chartForm, formPageIndex);
        }
    }, [getCurrentChart, initForm, pageIndex, currentForm, formHash, history, formType]);

    const handleNavigationToFormByIndex = async (index: number) => {
        const isFormExistOnCurrentForm = currentForm[index - 1];
        if (isFormExistOnCurrentForm) {
            navigateToNextForm(index);
            return;
        }

        const chart = getCurrentChart();
        if (chart) {
            const isExaminationForm = getChartFormById(chart, formHash)?.type === 'examination' || false;

            if (isExaminationForm) {
                navigationToChardEditPage(history, {
                    chartHash: chart.hash,
                    formType: PATIENT_FORM,
                    formHash: getPatientFormHash(charts, chart.hash),
                    formPageIndex: forms['patient'].length
                });
            }
        }
    };

    const navigateToNextForm = (index: any) => {
        updateActiveForm(undefined);
        navigationToChardEditPage(history, {
            chartHash,
            formType,
            formHash,
            formPageIndex: index
        });
    };

    const redirectToNewSubForm = async (formType: FormTypes) => {
        const formHash = uuid();

        createNewFormForChart(chartHash, formHash, formType);
        navigationToChardEditPage(history, {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        });
    };

    const activeFormIsValidAndSaved = async () => {
        if (!activeFormIsValid) {
            touchFormData(activeForm);
            return false;
        }

        await saveChart(chartHash, formHash);

        return true;
    };

    const initSyncCharts = async (redirectTo = routes.userHome + routes.homeDefault) => {
        try {
            await syncCharts(true);
        } catch (e) {
        }

        progressBarPercent = 0;
        history.push(redirectTo);
    };

    const goNext = async () => {
        if (isActiveFormLocked) {
            navigateToNextForm(pageIndex + 1);
            return;
        }

        if (isUserHasAccess([role.doctor], assignRoles) && !await activeFormIsValidAndSaved()) {
            return;
        }

        await handleNavigationToFormByIndex(pageIndex + 1);
    };

    const goNextNewSubFormOrExit = async () => {
        if (isActiveFormLocked) {
            clearEmptyChart();
            return;
        }

        if (isUserHasAccess([role.doctor], assignRoles) && !await activeFormIsValidAndSaved()) {
            return;
        }

        const chart: ChartInterface | null = getCurrentChart();
        if (chart) {
            if (!isChartHasSpecificForm(chart, EXAMINATION_FORM) && formType === PATIENT_FORM) {
                await redirectToNewSubForm(EXAMINATION_FORM);
                return;
            }

            if (!isChartHasSpecificForm(chart, MEDICINE_FORM) && formType === PATIENT_SHORT_FORM) {
                await redirectToNewSubForm(MEDICINE_FORM);
                return;
            }

            if (chart.forms.length === 2 && !chart.forms[0].locked) {
                navigationToChardEditPage(history, {
                    chartHash,
                    formType: chart.forms[1].type as FormTypes,
                    formHash: chart.forms[1].hash,
                    formPageIndex: FIRST_FORM_PAGE_INDEX
                });
                return;
            }
        }
    };

    const goBack = async () => {
        resetActiveForm();
        history.goBack();
    };

    const lock = async () => {
        if (isActiveFormLocked || disableSave) {
            clearEmptyChart();
            return;
        }

        if (isUserHasAccess([role.doctor], assignRoles) && !await activeFormIsValidAndSaved()) {
            return;
        }

        confirmLock(async () => {
            await setChartFormLocked(chartHash, formHash);

            if (formType === COVID_FORM) {
                const routeParamsOfNewChart = initNewChart(COVID_FORM, createNewChart);
                setSpinnerMessage('1/1 Chart szinkronizálása');

                try {
                    await syncCharts(true);
                } catch (e) {
                }

                progressBarPercent = 0;
                history.push(`/charts/${routeParamsOfNewChart.chartHash}/${routeParamsOfNewChart.formType}/${routeParamsOfNewChart.formHash}/${routeParamsOfNewChart.formPageIndex}`);
                setSpinnerMessage('');

                return;
            }

            await initSyncCharts();
        });
    };

    const lockAndEmail = async () => {
        console.log('lockAndEmail');
        const redirectTo = `/charts/${chartHash}/email`;
        if (isActiveFormLocked || disableSave) {
            history.push(redirectTo);
            return;
        }

        if (isUserHasAccess([role.doctor], assignRoles) && !await activeFormIsValidAndSaved()) {
            return;
        }

        confirmLock(async () => {
            await setChartFormLocked(chartHash, formHash);

            if (formType === COVID_FORM) {
                const routeParamsOfNewChart = initNewChart(COVID_FORM, createNewChart);
                try {
                    setSpinnerMessage('1/2 Chart szinkronizálása');
                    await syncCharts(false);

                    setSpinnerMessage('2/2 Email küldése');
                    const pdfTypesForCurrentChartType = getPdfTypesForCurrentChartType(COVID_FORM);
                    const payload = {
                        emails: activeForm.form_data.email.value,
                        hash: match.params.chartHash,
                        types: pdfTypesForCurrentChartType
                    };
                    const config = getAxiosConfigWithToken(token);
                    await sendEmail(payload, config, () => showSuccessAlert('Az email sikeresen elküldve.'), {
                        showToast,
                        hideSpinnerModal,
                        setSpinnerMessage
                    });
                } catch (e) {
                } finally {
                    hideSpinnerModal();
                    setSpinnerMessage('');
                    history.push(`/charts/${routeParamsOfNewChart.chartHash}/${routeParamsOfNewChart.formType}/${routeParamsOfNewChart.formHash}/${routeParamsOfNewChart.formPageIndex}`);
                    progressBarPercent = 0;
                }

                return;
            }

            await initSyncCharts(redirectTo);
        });
    };

    const confirmLock = (callback: any) => {
        swal({
            title: 'Véglegesíti a chartot?',
            text: 'A kitöltés lezárása után a korábban bevitt adatok már nem módosíthatók!',
            buttons: {
                confirm: {
                    text: 'Igen',
                    value: true
                },
                cancel: {
                    text: 'Nem',
                    value: false,
                    visible: true,
                }
            },
        }).then((val: any) => {
            if (val) {
                updateDisableSave(true);
                callback();
            }
        });
    };

    const scrollToTop = () => {
        // @ts-ignore
        contentRef.current.scrollToTop();
    };

    const onModalOpenerClickHandler = useCallback((event: any, inputName: string, dynamicTextReplaceObj = {}) => {
        console.log('[Edit.tsx] onModalOpenerClickHandler');
        setShowModal(true);
        setModalContent({id: inputName, replaceObj: dynamicTextReplaceObj});
    }, []);

    const onToggleButtonClick = useCallback((event: any, buttonIdentifier: string) => {
        const displayedRows = activeForm.form_data[buttonIdentifier].elementConfig.displayedRows;
        const noneOfTheInputsHaveValue = Object.keys(activeForm.form_data).filter(key => activeForm.form_data[key].elementConfig.button === buttonIdentifier).every(key => !activeForm.form_data[key].value);

        if (displayedRows.length === 0) {
            displayNextElementOfDynamicInputs(event, buttonIdentifier);
        } else if (noneOfTheInputsHaveValue) {
            onRowDeleteButtonClick(event, buttonIdentifier, hideDynamicRow);
        }
    }, [activeForm, displayNextElementOfDynamicInputs, hideDynamicRow]);

    let inputButtonHandlers: ButtonHandlerInterface[] = [
        {
            inputName: 'privacy_button',
            handler: onModalOpenerClickHandler
        },
        {
            inputName: 'payment_button',
            handler: onModalOpenerClickHandler
        },
        {
            inputName: 'button_behavior',
            handler: onToggleButtonClick
        },
        {
            inputName: 'covid_button',
            handler: (e: any, inputName: string) => onModalOpenerClickHandler(e, inputName, {
                'eventName': event.name, 'eventNameEN': event.name_en || event.name
            })
        }
    ];

    if (!isActiveFormLocked) {
        inputButtonHandlers.push(
            {
                inputName: ['drugs_button', 'infusion_button'],
                handler: addNewRowOfDynamicInput
            },
            {
                inputName: 'delete',
                handler: (e: any, inputName: string) => onRowDeleteButtonClick(e, inputName, deleteDynamicLastRow)
            }
        );
    }

    const chartForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            inputButtonHandlers={inputButtonHandlers}
            controlButtonActions={{
                goNext,
                goNextNewExamOrExit: goNextNewSubFormOrExit,
                goBack,
                lock,
                exit: clearEmptyChart,
                lockAndEmail
            }}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={isActiveFormLocked}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent scrollEvents={true} ref={contentRef}>
                <Header/>
                <IonProgressBar color="success" value={progressBarPercent}/>

                {chartForm()}

                {showModal && <Popup
                    showModal={showModal}
                    setShowModal={setShowModal}
                    contentObj={modalContent}
                />}
            </IonContent>
        </IonPage>
    );
};

export default Edit;
