import {EventInterface} from "../declarations";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../actions";

const Event = () => {
    const event: EventInterface = useSelector((state: any) => state.event);
    const events: EventInterface[] = useSelector((state: any) => state.events);

    const dispatch = useDispatch();
    const updateEvent = (payload: any, history: any) => dispatch(actions.updateEventOnServer(payload, history));
    const createEvent = (payload: any, history: any) => dispatch(actions.createEvent(payload, history));
    const setEvents = (events: EventInterface[]) => dispatch(actions.setEvents(events));

    return {
        event,
        events,
        updateEvent,
        setEvents,
        createEvent
    }
};

export default Event;
