import * as actionTypes from "./actionTypes";
import * as storageTypes from "../plugins/storageTypes";
import * as actions from "./index";
import {ChartInterface, EmptyChartParamsInterface, EventInterface} from "../declarations";
import {getStorage, setStorage} from "../plugins/storage";
import {
    fillUpChartFormFields,
    getChartByHash,
    getChartFormById, getSyncCharts,
    initEmptyChart, initEmptyForm, isAllFormsOfChartEmpty,
    isExistChartsInState, setCharInputFieldsSavedProperty
} from "../../utils/functions/chart";
import axios from "../../axios";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import _ from "lodash";
import {FormTypes} from "../types";
import {ALL_MAIN_FORM_TYPES, PATIENT_FORM, PATIENT_SHORT_FORM} from "../../utils/functions/constants";
import {hideSpinnerModal, showSpinnerModal, showSuccessAlert, uploadChartsToServer} from "./index";
import {isSyncChartEmpty} from "../../utils/functions/sync";

export const initChartsFromStorage = () => {
    return (dispatch: any) => {
        dispatch(loadChartsStart());

        return getStorage(storageTypes.CHARTS).then((charts: any) => {
            console.log('[actions/user.ts] initChartsFromStorage done');
            dispatch(loadChartsEnd());
            dispatch(setCharts(charts || []));
        }).catch((er: any) => {
            dispatch(loadChartError(er));
            dispatch(actions.showToast('Az adatok lekérése sikertelen volt. Lehet, hogy nincs internetkapcsolat. Kérem próbálja meg újra.'));
        });
    }
};

export const setCharts = (charts: ChartInterface[]) => {
    return {
        type: actionTypes.CHARTS_SET,
        charts: charts
    }
};

export const updateChartsInStore = (charts: ChartInterface[]) => {
    return (dispatch: any, getState: any) => {
        dispatch(setCharts(charts));
        console.log('[actions/sync.ts] updateChartsInStore');
        const syncCharts: ChartInterface[] = getSyncCharts(getState);
        if (isSyncChartEmpty(syncCharts)) {
            dispatch(actions.hideSyncAlert());
        } else {
            dispatch(actions.showSyncAlert());
        }

        return setStorage(storageTypes.CHARTS, charts).catch((err) => {
            dispatch(loadChartError(err));
            dispatch(actions.showToast('Az mentése sikertelen volt. Kérem próbálja meg újra.'));
        });
    }
};

export const loadChartsStart = () => {
    return {
        type: actionTypes.CHART_START
    }
};

export const loadChartsEnd = () => {
    return {
        type: actionTypes.CHART_END
    }
};

export const loadChartError = (error: any) => {
    return {
        type: actionTypes.CHARTS_LOAD_ERROR,
        error: error
    }
};

export const saveChart = (chartHash: string, formHash: any) => {
    return (dispatch: any, getState: any) => {
        console.log('[actions/charts.ts] saveChart');
        dispatch(loadChartsStart());
        let newCharts: ChartInterface[] = [];

        if (isExistChartsInState(getState)) {
            const charts = _.cloneDeep(getState().chart.charts);
            const chart = getChartByHash(charts, chartHash);

            if (chart) {
                const chartForm = getChartFormById(chart, formHash);
                chart.event_id = getState().event.id;

                if (chartForm) {
                    if (!chartForm.locked && chart.last_sync_date !== 0) {
                        console.log('[actions/charts.ts] set need sync');
                        chart.last_sync_date = 0;
                    }
                    const {form} = getState().activeForm;
                    fillUpChartFormFields(chartForm, form);
                    console.log('[actions/charts.ts] fillUpChartFormFields');
                }
            }

            newCharts = charts;
        }

        return dispatch(updateChartsInStore(newCharts));
    }
};

export const createNewChart = (chartHash: string, formHash: string, formType: FormTypes) => {
    return async (dispatch: any, getState: any) => {
        const charts = _.cloneDeep(getState().chart.charts);
        const emptyChartParams: EmptyChartParamsInterface = {
            eventId: getState().event.id,
            chartHash,
            userId: getState().user.id,
            formHash
        };
        const chart: ChartInterface = initEmptyChart(emptyChartParams, formType);

        charts.push(chart);
        await dispatch(updateChartsInStore(charts));
    }
};

export const createNewFormForChart = (chartHash: string, formHash: string, formType: FormTypes) => {
    return async (dispatch: any, getState: any) => {
        console.log('[actions/chart.ts] createNewFormForChart');
        const updatedCharts: ChartInterface[] = _.cloneDeep(getState().chart.charts);
        const chart = updatedCharts.find((chart) => chart.hash === chartHash);
        const emptyForm = initEmptyForm(formType, getState().user.id, formHash);

        if (chart) {
            chart.last_sync_date = 0;
            chart?.forms.push(emptyForm);
            await dispatch(updateChartsInStore(updatedCharts));
        }
    }
};


export const updateChartsSyncDate = (charts: ChartInterface[], lastSyncDate: number) => {
    return (dispatch: any, getState: any) => {
        const updatedCharts = _.cloneDeep(getState().chart.charts);
        const chartHashs = charts.map((chart: ChartInterface) => chart.hash);
        console.log('[actions/sync.ts] updateChartsSyncDate');

        updatedCharts.forEach((chart: ChartInterface) => {
            if (chartHashs.indexOf(chart.hash) > -1) {
                chart.last_sync_date = lastSyncDate;
            }
        });

        return dispatch(updateChartsInStore(updatedCharts));
    }
};

export const getChartsFromServer = (eventId?: number, mainFormTypes: string[] = ALL_MAIN_FORM_TYPES) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        const data = {event_id: eventId || '', 'listTypes': mainFormTypes};

        return axios.post(apiEndpoints.API_SYNC_GET_CHARTS, data, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[actions/sync.ts] API_SYNC_GET_CHARTS', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                const charts: ChartInterface[] = response.data.payload;
                console.log('[actions/sync.ts] API_SYNC_GET_CHARTS');
                setCharInputFieldsSavedProperty(charts);

                return dispatch(updateChartsInStore(charts));
            })
            .catch((e) => {
                console.error('[sync.ts] API_SYNC_GET_CHARTS', e);
                dispatch(actions.showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
            });
    }
};

export const setChartFormLocked = (chartHash: string, formHash: string) => {
    return (dispatch: any, getState: any) => {
        console.log('[actions/charts.ts] setChartFormLocked');
        const updatedCharts = _.cloneDeep(getState().chart.charts);
        const chart: ChartInterface = updatedCharts.find((chart: ChartInterface) => chart.hash === chartHash);
        const form = chart.forms.find((form) => form.hash === formHash);
        const patientForm = chart.forms.find((form) => [PATIENT_FORM, PATIENT_SHORT_FORM].indexOf(form.type) >= 0);

        if (form) {
            form.locked = true;
        }

        if (patientForm) {
            patientForm.locked = true;
        }

        return dispatch(updateChartsInStore(updatedCharts));
    }
};

export const getChartByEvent = (event: EventInterface, mainFormTypes: string[]) => {
    return async (dispatch: any) => {
        dispatch(loadChartsStart());
        dispatch(actions.updateEvent(event));
        await dispatch(actions.getChartsFromServer(event.id, mainFormTypes));
        dispatch(loadChartsEnd());
    }
};

export const syncCharts = (readFile: any, hideSpinnerAfterDone: boolean = true) => {
    return async (dispatch: any) => {
        dispatch(showSpinnerModal());
        console.log('[Edit.tsx] synChart');
        const lastSyncDate = new Date().getTime();

        await dispatch(uploadChartsToServer(lastSyncDate, true, readFile, hideSpinnerAfterDone));
        dispatch(showSuccessAlert('Az űrlap sikeresen mentve az eszközre.'));
        if (hideSpinnerAfterDone) {
            dispatch(hideSpinnerModal());
        }
    };
};

export const setShortFormToStandardAndUnlock = (chartHash: string) => {
    return (dispatch: any, getState: any) => {
        console.log('[actions/charts.ts] setShortFormToStandardAndUnlock');
        const updatedCharts = _.cloneDeep(getState().chart.charts);
        const chart: ChartInterface = updatedCharts.find((chart: ChartInterface) => chart.hash === chartHash);
        const patientShortForm = chart.forms.find((form) => form.type === PATIENT_SHORT_FORM);

        if (patientShortForm) {
            patientShortForm.locked = false;
            patientShortForm.type = PATIENT_FORM;
        }

        return dispatch(updateChartsInStore(updatedCharts));
    }
};

export const deleteEmptyChart = (deletedChartHash: string) => {
    return (dispatch: any, getState: any) => {
        const charts: ChartInterface[] = _.cloneDeep(getState().chart.charts);
        const index = charts.findIndex((chart) => chart.hash === deletedChartHash);

        if (index > -1 && isAllFormsOfChartEmpty(charts[index])) {
            charts.splice(index, 1);
        }

        return dispatch(updateChartsInStore(charts));
    };
};

export const deleteEmptyForm = (chartHash: string, formHash: string) => {
    return (dispatch: any, getState: any) => {
        const charts: ChartInterface[] = _.cloneDeep(getState().chart.charts);
        const chart = charts.find((chart) => chart.hash === chartHash);
        const form = chart?.forms.find(form => form.hash === formHash);

        if (chart && form && form.input_fields.length === 0) {
            chart.forms.splice(chart.forms.indexOf(form), 1);
        }

        return dispatch(updateChartsInStore(charts));
    };
};
