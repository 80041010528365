import {set, get, del} from 'idb-keyval';

export const clearStorage = async (key: string) => {
    await del(key);
};

export const setStorage = async (key: string, object: object) => {
    await set(key, object);
};

export const getStorage = async (key: string) => {
    return new Promise((resolve) => {
        get(key).then((response: any) => resolve(response));
    });
};
