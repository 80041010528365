import React from 'react';
import {IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave} from "@ionic/react";
import {RouteComponentProps, useHistory} from "react-router";
import _ from "lodash";

import {getAxiosConfigWithToken} from "../../../utils/functions/http";
import {
    AppendFormWithOptions,
    getEventInputData,
    onRowDeleteButtonClick,
    populateDynamicInputGroups,
} from "../../../utils/functions/form";
import {routes} from "../../../utils/consants/routes";
import form from "../../../store/static/forms/event/event"
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import Spinner from "../../../components/Spinner/Spinner";
import {ActiveFormState, AuthState, EventState} from "../../../store/states";

const Create: React.FC<RouteComponentProps> = (props) => {
    const {
        updateActiveForm,
        activeForm,
        doSave,
        resetActiveForm,
        inputHandler,
        addNewRowOfDynamicInput,
        deleteDynamicLastRow
    } = ActiveFormState();
    const {createEvent} = EventState();
    const {token} = AuthState();

    const history = useHistory();

    useIonViewWillEnter(async () => {
        console.log('useIonViewWillEnter');
        const config = getAxiosConfigWithToken(token);
        const appendedForm = await AppendFormWithOptions({...form}, config);

        if (!appendedForm) {
            return;
        }

        appendedForm.form_data = populateDynamicInputGroups(appendedForm.form_data, {'place_of_care_dynamic': 1}, 1)
        updateActiveForm(_.cloneDeep(appendedForm));
    });

    useIonViewWillLeave(() => resetActiveForm());

    const inputButtonHandlers = [
        {
            inputName: 'place_of_care_button',
            handler: addNewRowOfDynamicInput
        },
        {
            inputName: 'delete',
            handler: (e: any, inputName: string) => onRowDeleteButtonClick(e, inputName, deleteDynamicLastRow)
        }
    ];

    const save = () => {
        doSave(() => {
            const payload = getEventInputData(activeForm.form_data);
            createEvent(payload, history);
        });
    };

    const exit = () => {
        history.push(routes.eventList);
    };

    const drawForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={{
                save,
                exit,
            }}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            inputButtonHandlers={inputButtonHandlers}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Új esemény hozzáadása</h1>

                {drawForm()}
            </IonContent>
        </IonPage>
    );
};

export default Create;
