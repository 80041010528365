import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";
import {FormTypes} from "../types";
import {useFilesystem} from "@ionic/react-hooks/filesystem";
import {ChartInterface} from "../declarations";

const Chart = () => {
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);
    const isChartsLoading: boolean = useSelector((state: any) => state.chart.loading);

    const dispatch = useDispatch();
    const saveChart = async (chartHash: string, formHash: number) => dispatch(actions.saveChart(chartHash, formHash));
    const setChartFormLocked = async (chartHash: string, formHash: string) => dispatch(actions.setChartFormLocked(chartHash, formHash));
    const createNewFormForChart = (chartHash: string, formHash: string, formType: FormTypes) => dispatch(actions.createNewFormForChart(chartHash, formHash, formType));
    const syncCharts = async (hideSpinnerAfterDone: boolean) => dispatch(actions.syncCharts(readFile, hideSpinnerAfterDone));
    const synChartsFromLocalToServer = async (lastSyncDate: number) => dispatch(actions.uploadChartsToServer(lastSyncDate, true, readFile));
    const setCharts = (charts: ChartInterface[]) => dispatch(actions.setCharts(charts));
    const createNewChart = (chartHash: string, formHash: string, formType: FormTypes) => dispatch(actions.createNewChart(chartHash, formHash, formType));
    const setShortFormToStandardAndUnlock = (chartHash: string) => dispatch(actions.setShortFormToStandardAndUnlock(chartHash));
    const deleteEmptyChart = async (chartHash: string) => dispatch(actions.deleteEmptyChart(chartHash));
    const deleteEmptyForm = async (chartHash: string, formHash: string) => dispatch(actions.deleteEmptyForm(chartHash, formHash));

    const {readFile} = useFilesystem();

    return {
        charts,
        isChartsLoading,
        saveChart,
        setChartFormLocked,
        createNewFormForChart,
        syncCharts,
        synChartsFromLocalToServer,
        setCharts,
        createNewChart,
        setShortFormToStandardAndUnlock,
        deleteEmptyChart,
        deleteEmptyForm
    }
};

export default Chart;
