import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../utils/functions/common";
import _ from "lodash";

const initialState = {
    form: undefined,
    isValid: false,
    isLocked: false
};

const updateActiveForm = (state: any, action: any) => {
    return updateObject(state, {form: action.form});
};

const updateActiveFormState = (state: any, action: any) => {
    return updateObject(state, {form: action.form, isValid: action.isValid});
};

const updateIsValid = (state: any, action: any) => {
    return updateObject(state, {isValid: action.isValid});
};

const updateIsLocked = (state: any, action: any) => {
    return updateObject(state, {isLocked: action.isLocked});
};

const reset = (state: any) => {
    return updateObject(state, _.cloneDeep(initialState));
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ACTIVE_FORM_UPDATE:
            return updateActiveForm(state, action);
        case actionTypes.ACTIVE_FORM_UPDATE_IS_VALID:
            return updateIsValid(state, action);
        case actionTypes.ACTIVE_FORM_UPDATE_IS_LOCKED:
            return updateIsLocked(state, action);
        case actionTypes.ACTIVE_FORM_RESET:
            return reset(state);
        case actionTypes.ACTIVE_FORM_UPDATE_WHOLE:
            return updateActiveFormState(state, action);
        default:
            return state;
    }
};

export default reducer;

