import React from "react";
import classes from "./Icon.module.scss";
import {IonRow} from "@ionic/react";
import logo from "../../assets/images/logo.png";

const Icon = () => {
    return (
        <IonRow>
            <img className={classes["logo"]} src={logo} alt="logo"/>
        </IonRow>
    );
};

export default Icon;
