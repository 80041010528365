import athlete_data from "./1_athlete_data";
import photos from "./2_photos";
import privacy_payment from "./3_privacy";
import accreditation from "./4_accreditation";
import complaints_injury from "./5_complaints";
import drug_allergies_history from "./6_drug_allergies";

import {FormInterface} from "../../../../declarations";
import {
    buttonExit,
    buttonGoBack, buttonGoNext,
    buttonGoNextNewExamOrExit,
    exitAndGoNextButton,
    exitAndGoNextButtonWithGoBack
} from "../../../controllButtons/controllButtons";

const patientShort: FormInterface[] = [
    {
        form_name: "Adatvédelmi nyilatkozat / Privacy statement",
        form_data: privacy_payment,
        buttons: exitAndGoNextButton
    },
    {
        form_name: "Páciens adatai / Patient information",
        form_data: athlete_data,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Fotók / Photos",
        form_data: photos,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Akkreditáció / Accreditation",
        form_data: accreditation,
        buttons: [
            buttonGoBack,
            buttonExit,
            buttonGoNextNewExamOrExit
        ]
    }
];

const patient: FormInterface[] = [
    patientShort[0],
    patientShort[1],
    patientShort[2],
    {
        form_name: "Akkreditáció / Accreditation",
        form_data: accreditation,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Panaszok / Complaints",
        form_data: complaints_injury,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Kórelőzmény / Relevant history",
        form_data: drug_allergies_history,
        buttons: [
            buttonGoBack,
            buttonExit,
            buttonGoNextNewExamOrExit
        ]
    }
];

export {patient as default, patientShort};
