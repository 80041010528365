import {FormButtonInterface} from "../../declarations";

export const buttonGoBack: FormButtonInterface = {
    action: 'goBack',
    text: 'Vissza',
    position: 'left',
    type: 'primary',
};

export const buttonExit: FormButtonInterface = {
    action: 'exit',
    text: 'Mégse',
    position: 'right',
    type: 'danger',
};

export const buttonGoNext: FormButtonInterface = {
    action: 'goNext',
    text: 'Tovább',
    position: 'right',
    type: 'success',
};

export const buttonSave: FormButtonInterface = {
    action: 'save',
    text: 'Mentés',
    position: 'right',
    type: 'success',
};

export const buttonGoNextNewExamOrExit: FormButtonInterface = {
    action: 'goNextNewExamOrExit',
    text: 'Tovább',
    position: 'right',
    type: 'success',
};

export const buttonLock: FormButtonInterface = {
    action: 'lock',
    text: 'Lezárás',
    position: 'right',
    type: 'success',
};

export const buttonLockAndEmail: FormButtonInterface = {
    action: 'lockAndEmail',
    text: 'Lezárás és email küldés',
    position: 'right',
    type: 'success',
    roles: ['doctor']
};

export const exitAndGoNextButton: FormButtonInterface[] = [
    buttonExit,
    buttonGoNext
];

export const exitAndGoNextButtonWithGoBack: FormButtonInterface[] = [
    buttonGoBack,
    ...exitAndGoNextButton
];
