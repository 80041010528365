import * as actions from "../../store/actions";
import axios from "axios";
import {isMobile} from "./device";

export const initAuthFromStorage = (dispatch: any, auth: any) => {
    return new Promise(async (resolve: any) => {
        const expirationDate = new Date(String(auth.expirationDate));
        console.log('[functions/auth.ts] initAuthFromStorage');

        if (isExpired(expirationDate)) {
            console.log('[functions/auth.ts] auth expired');
            dispatch(actions.logout());
            return;
        }

        console.log('[functions/auth.ts] init full data from storage');
        await dispatch(actions.initUserFromStorage());
        await dispatch(actions.initEventFromStorage());
        await dispatch(actions.initChartsFromStorage());

        dispatch(actions.authSuccess(auth.token, expirationDate));
        dispatch(actions.checkAuthTimeout(expiresIn(expirationDate)));
        console.log('[functions/auth.ts] authLoadingEnd');
        resolve();
    });
};

export const onAuthSuccessRequest = async (dispatch: any, response: any) => {
    console.log('[functions/auth.ts] onAuthSuccessRequest init');
    const idToken = response.data.payload.idToken;
    const expiresIn = response.data.payload.expiresIn;
    const expirationDate = new Date(
        new Date().getTime() + expiresIn * 1000
    );

    dispatch(actions.checkAuthTimeout(expiresIn));

    await dispatch(actions.updateAuth(idToken, expirationDate));
    console.log('[functions/auth.ts] updateAuth done');
    await dispatch(actions.getSyncDataFromServer(idToken));

    if (isMobile) {
        await dispatch(actions.initChartsFromStorage());
    }

    console.log('[functions/auth.ts]authLoadingEnd');
    dispatch(actions.authLoadingEnd());
};

export const isExpired = (expirationDate: Date) => {
    return expirationDate <= new Date();
};

export const expiresIn = (expirationDate: Date) => {
    return String((expirationDate.getTime() - new Date().getTime()) / 1000);
};

export const login = (authData: any, dispatch: any, apiPath: string) => {
    axios
        .post(apiPath, authData)
        .then(response => {
            if (response.data.success !== true) {
                console.error('[auth.ts] authFail', response);
                dispatch(actions.authFail(true));
                return;
            }

            console.log('[actions/auth.ts] authSuccess');
            onAuthSuccessRequest(dispatch, response);
        })
        .catch(err => {
            console.error('[auth.ts] API_AUTH_DOCTOR_LOGIN error', err);
            dispatch(actions.authFail(true));
            dispatch(actions.showToast('A szerverhez való csatlakozás sikertelen volt. Lehet, hogy nincs internetkapcsolat. Kérem próbálja meg újra.'));
        });
};

export const isUserHasAccess = (roles: string[] | undefined, assignRoles: string[]) => !(roles && assignRoles && roles?.find((role) => assignRoles.indexOf(role) > -1) === undefined);
