import {FormDataInterface, FormInterface} from "../../../declarations";
import {buttonExit, buttonSave} from "../../controllButtons/controllButtons";
import {
    accreditation, address,
    city, emails, end,
    hospital, logo,
    name_en, password,
    start, name, event_sports, covid_text, insurance_text, insurance_text_en,
} from "../../inputFields/inputFields";
import {defaultFormConfig} from "../helper/helper";

const event: FormDataInterface = {
    name,
    name_en,
    password: {
        ...password,
        elementConfig: {
            type: 'text'
        }
    },
    emails,
    start,
    end,
    address,
    city,
    sports: {
        ...event_sports
    },
    hospital,
    delete_place_of_care: {
        label: 'Törlés',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            row: 'place_of_care_row_2',
            button: 'place_of_care_button',
            hide: false,
            hideLabel: true,
            buttonColor: 'danger',
            colSize: '1',
            buttonIcon: 'remove',
            classes: 'button-shrink'
        },
        text: 'Törlés',
        validation: {
            required: false,
        },
        ...defaultFormConfig,
        valid: true
    },
    place_of_care_button: {
        label: 'További ellátási helyszín',
        helper: '&nbsp;',
        elementType: 'button',
        elementConfig: {
            type: 'button',
            displayedRows: [],
            hide: false,
            hideLabel: true,
            colSize: '12',
            buttonIcon: 'add',
            inputGroupTemplate: 'place_of_care_dynamic'
        },
        validation: {
            required: false,
        },
        ...defaultFormConfig,
        valid: true
    },
    accreditation,
    logo,
    covid_text,
    insurance_text,
    insurance_text_en
};

const forms: FormInterface = {
    form_name: "",
    form_data: event,
    buttons: [
        buttonExit,
        buttonSave
    ]
};

export default forms;
