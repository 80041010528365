import {useCallback} from "react";
import {FormInterface} from "../declarations";
import * as actions from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";

const ActiveForm = () => {
    const {form: activeForm, isValid: activeFormIsValid, isLocked: isActiveFormLocked} = useSelector((state: any) => state.activeForm);

    const dispatch = useDispatch();
    const updateActiveForm = useCallback((form: FormInterface | undefined) => dispatch(actions.updateActiveForm(form)), [dispatch]);
    const updateActiveFormIsLocked = useCallback((isLocked: boolean) => dispatch(actions.updateActiveFormIsLocked(isLocked)), [dispatch]);
    const setFormValidity = useCallback((form: FormInterface) => dispatch(actions.setFormValidity(form)), [dispatch]);
    const inputHandler = useCallback((event: any, inputIdentifier: string) => dispatch(actions.inputHandler(event, inputIdentifier)), [dispatch]);
    const touchFormData = useCallback((form: FormInterface) => dispatch(actions.touchFormData(form)), [dispatch]);
    const validateForm = useCallback((form: FormInterface) => dispatch(actions.validateForm(form)), [dispatch]);
    const doSave = useCallback((callback: any) => dispatch(actions.doSave(callback)), [dispatch]);
    const resetActiveForm = useCallback(() => dispatch(actions.resetActiveForm()), [dispatch]);
    const hideDynamicRow = useCallback((deleteButtonIdentifier: any) => dispatch(actions.hideDynamicRow(deleteButtonIdentifier)), [dispatch]);
    const displayNextElementOfDynamicInputs = useCallback((event: any, statusInputElementID: string) => dispatch(actions.displayNextElementOfDynamicInputs(event, statusInputElementID)), [dispatch]);
    const addNewRowOfDynamicInput = useCallback((event: any, statusInputElementID: string) => dispatch(actions.addNewRowOfDynamicInput(event, statusInputElementID)), [dispatch]);
    const deleteDynamicLastRow = useCallback((statusInputElementID: string) => dispatch(actions.deleteDynamicLastRow(statusInputElementID)), [dispatch]);

    return {
        activeForm,
        activeFormIsValid,
        isActiveFormLocked,
        updateActiveForm,
        touchFormData,
        updateActiveFormIsLocked,
        setFormValidity,
        validateForm,
        inputHandler,
        doSave,
        resetActiveForm,
        hideDynamicRow,
        displayNextElementOfDynamicInputs,
        addNewRowOfDynamicInput,
        deleteDynamicLastRow
    }
};

export default ActiveForm;
