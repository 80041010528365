import medical_examinations from "./1_medical_examinations";
import drugs, {drugs_only} from "./2_drugs";
import note from "./3_notes";
import care_type from "./4_type_of_care";
import admission_place from "./5_admission_to";
import signatures from "./6_signatures";

import {FormInterface} from "../../../../declarations";
import {
    buttonExit,
    buttonGoBack,
    buttonLock,
    buttonLockAndEmail,
    exitAndGoNextButtonWithGoBack
} from "../../../controllButtons/controllButtons";

const medicine: FormInterface[] = [
    {
        form_name: "Beavatkozások / Interventions",
        //info: 'Beleegyező nyilatkozat antidopping szabályzás hatálya alá eső páciens számára gyógyszeres kezelés elfogadásához. / Informed consent for accepting medication for a patient subject to antidoping regulations.<br><small>(A mezők kitöltése nem kötelező)</small>',
        form_data: drugs_only,
        buttons: [
            buttonGoBack,
            buttonExit,
            buttonLock,
            buttonLockAndEmail
        ]
    },
];

const examination: FormInterface[] = [
    {
        form_name: "Orvosi vizsgálatok / Medical examinations",
        form_data: medical_examinations,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Beavatkozások / Interventions",
        //info: 'Beleegyező nyilatkozat antidopping szabályzás hatálya alá eső páciens számára gyógyszeres kezelés elfogadásához. / Informed consent for accepting medication for a patient subject to antidoping regulations.<br><small>(A mezők kitöltése nem kötelező)</small>',
        form_data: drugs,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Megjegyzés / Notes",
        form_data: note,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Az ellátás típusa / Type of care",
        form_data: care_type,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Az ellátás helyszíne / Admission to",
        form_data: admission_place,
        buttons: exitAndGoNextButtonWithGoBack
    },
    {
        form_name: "Aláírások / Signatures",
        form_data: signatures,
        buttons: [
            buttonGoBack,
            buttonExit,
            buttonLock,
            buttonLockAndEmail
        ]
    }
];

export {examination as default, medicine};
