import {FormDataInterface} from "../../../../declarations";
import {
    accreditation_no,
    covid_button,
    covid_consent,
    date_of_birth, email,
    first_name,
    last_name, patient_signature_covid
} from "../../../inputFields/inputFields";

const covidConsent: FormDataInterface = {
    first_name,
    last_name,
    date_of_birth,
    accreditation_no,
    covid_button,
    covid_consent,
    email,
    patient_signature_covid
};

export default covidConsent;
