import * as actionTypes from "./actionTypes";

export const showSpinnerModal = () => {
    return {
        type: actionTypes.SPINNER_MODAL_SHOW
    }
};

export const hideSpinnerModal = () => {
    return {
        type: actionTypes.SPINNER_MODAL_HIDE
    }
};

export const setSpinnerMessage = (message: string) => {
    return {
        type: actionTypes.SPINNER_SET_MESSAGE,
        message
    }
};
