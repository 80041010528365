import React from "react";
import {IonCol, IonRow, IonIcon, IonBadge} from "@ionic/react";
import {
    alert, checkmark, sync, chevronDownOutline
} from "ionicons/icons";
import iconAmbulance from '../../assets/images/mento_ikon.png'
import Moment from "react-moment";

import {ChartInterface} from "../../store/declarations";
import {getFieldValue} from "../../utils/functions/form";

import classes from "./Content.module.scss";
import {getAllChartFormByType, isFormsValid} from "../../utils/functions/chart";
import {emptyInputFields} from "../../store/static/forms/input";
import {
    CERTIFICATE_FORM,
    COVID_FORM,
    EXAMINATION_FORM,
    MEDICINE_FORM,
    PATIENT_FORM,
    PATIENT_SHORT_FORM
} from "../../utils/functions/constants";
import {DATE_FORMAT} from "../../utils/consants/common";
import SubFormList from "../ExaminationList/SubFormList";
import ChartControlButtons from "../UI/ChartControlButtons/ChartControlButtons";
import cross from "../../assets/images/Medical_cross.svg";

interface ContentProps extends ChartInterface {
    onClickHandlers: any;
    showExaminationList: any;
}

const content: React.FC<ContentProps> = chart => {
    const {
        hash,
        last_sync_date,
        forms,
        showExaminations,
        onClickHandlers,
        showExaminationList,
        created_date
    } = chart;
    const mainForm = chart.forms.find(form => [PATIENT_FORM, PATIENT_SHORT_FORM, COVID_FORM, CERTIFICATE_FORM].indexOf(form.type) >= 0);
    const examinations = getAllChartFormByType(chart, EXAMINATION_FORM);
    const drugs = getAllChartFormByType(chart, MEDICINE_FORM);
    const totalExaminations = forms.length - 1;

    let chevronClasses = [classes['content__icons']];
    let input_fields = mainForm?.input_fields || emptyInputFields;

    if (showExaminations) {
        chevronClasses.push(classes['content__icons--rotate-180deg']);
    }

    let isValidBadgeTemplate = <IonIcon color="danger" icon={alert}/>;

    if (isFormsValid(chart)) {
        isValidBadgeTemplate = <IonIcon color="success" icon={checkmark}/>;
    }

    let showAmbulance: any = null;
    for (let examination of examinations) {
        for (let field of examination.input_fields) {
            if (field.name === "type_of_care" && (field.value.includes('F ') || field.value.includes('E '))) {
                showAmbulance = <img src={iconAmbulance} alt=""/>;
                break;
            }
        }
        if (showAmbulance != null) {
            break;
        }
    }

    const dateOfBirth = getFieldValue('date_of_birth', input_fields);


    return (
        <IonRow className={classes["content"]}>
            <IonCol>
                <h2 className={classes["content__title"]}>

                    {getFieldValue('last_name', input_fields) + ' ' + getFieldValue('first_name', input_fields)}

                    {[COVID_FORM, CERTIFICATE_FORM].every(formType => formType !== mainForm?.type) &&
                    <IonBadge color="light" className="block">{totalExaminations}</IonBadge>}

                    {isValidBadgeTemplate}
                    {showAmbulance}
                    {last_sync_date === 0 && <IonIcon color="danger" icon={sync}/>}
                    {(mainForm?.type === PATIENT_SHORT_FORM) &&
                    <img width="30" src={cross} alt="" className={classes['content__cross']}/>}
                </h2>

                {dateOfBirth && <p className={classes["content__description"]}>
                    Születési dátum: <Moment date={new Date(dateOfBirth)} format={DATE_FORMAT}/>
                </p>}
                {forms.length === 1 && <p className={classes["content__description"]}>
                    Dátum: <Moment date={+created_date} format={DATE_FORMAT}/>
                </p>}
            </IonCol>

            {[COVID_FORM, CERTIFICATE_FORM].every(formType => formType !== mainForm?.type) &&
            <IonCol className="ion-text-center" onClick={() => showExaminationList(hash)}>
                <IonIcon className={chevronClasses.join(' ')} icon={chevronDownOutline}/>
            </IonCol>}

            <IonCol className={classes["content__actions"]}>
                <ChartControlButtons
                    editPatientFormOnclickHandler={onClickHandlers.editPatientFormOnclickHandler}
                    hash={hash}
                    chooseNewSubFormType={onClickHandlers.chooseNewSubFormType}
                    isPatientFormValid={mainForm?.locked || false}
                    mainFormType={mainForm?.type}
                />
            </IonCol>

            {(mainForm?.type === PATIENT_FORM) && showExaminations && <IonCol size="12">
                <h3>Vizsgálatok</h3>
                <SubFormList
                    hash={hash}
                    forms={examinations}
                    editSubFormOnclickHandler={onClickHandlers.editExaminationFormOnclickHandler}
                    formType={EXAMINATION_FORM}
                />
            </IonCol>}
            {showExaminations && drugs.length > 0 && <IonCol size="12">
                <h3>Gyógyszerkiadások</h3>
                <SubFormList
                    hash={hash}
                    forms={drugs}
                    editSubFormOnclickHandler={onClickHandlers.editExaminationFormOnclickHandler}
                    formType={MEDICINE_FORM}
                />
            </IonCol>}
        </IonRow>
    );
};

export default content;
