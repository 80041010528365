import React, {useCallback, useEffect, useState} from "react";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router-dom";
import {
    IonApp,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonRouterOutlet,
    IonToast,
    IonToolbar,
    IonPage
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {useFilesystem} from "@ionic/react-hooks/filesystem";

import List from "./containers/Charts/List/List";
import Edit from "./containers/Charts/Edit/Edit";
import Email from "./containers/Charts/Email/Email";
import Pdf from "./containers/Charts/Pdf/Pdf"

import Sync from "./containers/Sync/Sync";
import Login from "./containers/Login/Login";
import UserEdit from "./containers/User/UserEdit/UserEdit";
import Statistics from "./containers/Statistics/Statistics";
import EventList from "./containers/Events/List/List";
import EventCreate from "./containers/Events/Create/Create";
import EventEdit from "./containers/Events/Edit/Edit";
import HospitalList from "./containers/Hospitals/List/List";
import HospitalCreate from "./containers/Hospitals/Create/Create";
import HospitalEdit from "./containers/Hospitals/Edit/Edit";

import Spinner from "./components/Spinner/Spinner";
import Menu from "./components/Menu/Menu";
import Modal from "./components/UI/Modal/Modal";
import ProtectedRoute from "./hoc/protectedRoute/protectedRoute";
import * as actions from "./store/actions/index";
import {searchOutline} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/core.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.module.scss";
import classes from "./App.module.scss";
import {routes} from "./utils/consants/routes";
import Admin from "./containers/Admin/Admin";
import {role} from "./utils/consants/auth";
import {isUserHasAccess} from "./utils/functions/auth";

const App: React.FC<RouteComponentProps> = (props) => {
    const showToast = useSelector((state: any) => state.toast.show);
    const isAuthLoaded = useSelector((state: any) => !state.auth.loading);
    const toastMessage = useSelector((state: any) => state.toast.message);
    const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
    const {show: isModalShow, message: spinnerMessage} = useSelector((state: any) => state.spinnerModal);
    const {assignRoles} = useSelector((state: any) => state.user);

    const {readFile} = useFilesystem();

    const dispatch = useDispatch();
    const hideToast = () => dispatch(actions.hideToast());
    const showSpinnerModal = useCallback(() => dispatch(actions.showSpinnerModal()), [dispatch]);
    const hideSpinnerModal = useCallback(() => dispatch(actions.hideSpinnerModal()), [dispatch]);
    const startAutoSync = useCallback(() => dispatch(actions.startAutoSync(readFile)), [dispatch, readFile]);
    const onTryAutoSignUp = useCallback(() => dispatch(actions.authCheckState()), [dispatch]);

    const [showSearchBar, updateShowSearchBar] = useState(false);

    useEffect(() => {
            onTryAutoSignUp();
            startAutoSync();
        }, [startAutoSync, onTryAutoSignUp]
    );

    useEffect(() => {
        if (isAuthLoaded) {
            setTimeout(() => hideSpinnerModal(), 500);
        } else {
            showSpinnerModal();
        }
    }, [isAuthLoaded, showSpinnerModal, hideSpinnerModal]);

    const header = isAuthenticated && (
        <IonHeader className={classes["navigation"]}>
            <IonToolbar
                color="primary"
                className={classes["navigation__toolbar"]}
            >
                <IonButtons slot="start">
                    <IonMenuButton/>
                </IonButtons>
                <IonIcon
                    className={classes["navigation__search-icon"]}
                    icon={searchOutline}
                    onClick={() => {
                        updateShowSearchBar(!showSearchBar);
                    }}
                />
            </IonToolbar>
        </IonHeader>
    );

    return (
        <IonApp>
            {isAuthenticated && <Menu {...props} />}

            <IonPage id="main-content" className={classes['app']}>
                {header}
                    <IonContent>
                    {isAuthLoaded && (
                    <IonRouterOutlet>
                        <ProtectedRoute exact path={routes.adminHome} component={Admin} roles={[role.admin]}/>
                        <ProtectedRoute exact path={routes.statistics} component={Statistics} roles={[role.admin]}/>
                        <ProtectedRoute
                            exact
                            path={routes.eventList}
                            roles={[role.admin]}
                            render={(props: any) => (
                                <EventList
                                    showSearchBar={showSearchBar}
                                    updateShowSearchBar={updateShowSearchBar}
                                    {...props}
                                />
                            )}
                        />
                        <ProtectedRoute exact path={routes.eventNew} component={EventCreate} roles={[role.admin]}/>
                        <ProtectedRoute exact path={routes.eventEdit} component={EventEdit} roles={[role.admin]}/>
                        <ProtectedRoute
                            exact
                            path={routes.hospitalList}
                            roles={[role.admin]}
                            render={(props: any) => (
                                <HospitalList
                                    showSearchBar={showSearchBar}
                                    updateShowSearchBar={updateShowSearchBar}
                                    {...props}
                                />
                            )}
                        />
                        <ProtectedRoute exact path={routes.hospitalNew} component={HospitalCreate}
                                        roles={[role.admin]}/>
                        <ProtectedRoute exact path={routes.hospitalEdit} component={HospitalEdit}
                                        roles={[role.admin]}/>

                        <ProtectedRoute exact path={routes.sync} component={Sync} roles={[role.doctor]}/>
                        <ProtectedRoute exact path={routes.userEdit} component={UserEdit}
                                        roles={[role.doctor, role.admin]}/>
                        <ProtectedRoute exact path={routes.chartEmail} component={Email} roles={[role.doctor]}/>
                        <ProtectedRoute exact path={routes.chartPdf} component={Pdf}
                                        roles={[role.doctor, role.admin]}/>
                        <Route exact path={routes.chartEdit}
                               render={(props: any) => {
                                   if (!isAuthenticated) {
                                       return <Redirect to={routes.login}/>;
                                   }
                                   return <Edit {...props}/>
                               }}
                        />
                        <Route exact path={routes.userHomeExtended}
                               render={(props: any) => {
                                   if (!isAuthenticated) {
                                       return <Redirect to={routes.login}/>;
                                   }
                                   return (
                                       <List
                                           showSearchBar={showSearchBar}
                                           updateShowSearchBar={updateShowSearchBar}
                                           {...props}
                                       />
                                   )
                               }}
                        />
                        <Route exact path={routes.login}
                               render={(props: any) => {
                                   if (isAuthenticated) {
                                       if (isUserHasAccess([role.admin], assignRoles)) {
                                           return <Redirect to={routes.adminHome}/>
                                       }
                                       return <Redirect to={routes.userHome + routes.homeDefault}/>;
                                   }
                                   return (
                                       <Login {...props}/>
                                   )
                               }}
                        />
                        <Route exact path="/" render={() => <Redirect to={routes.userHome + routes.homeDefault}/>}/>
                        <Redirect to={routes.userHome + routes.homeDefault}/>
                    </IonRouterOutlet>)}
                </IonContent>
            </IonPage>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    hideToast();
                }}
                message={toastMessage}
                duration={3500}
            />

            <Modal
                show={isModalShow}
                modalClosed={null}
            >
                <Spinner>
                    {spinnerMessage}
                </Spinner>
                <h3 className={"ion-text-center"}>Kérem várjon...</h3>
            </Modal>
        </IonApp>
    );
};

export default withRouter(App);
