import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../utils/functions/common";

const initialState = {
    show: true,
    message: ''
};

const showSpinnerModal = (state: any, action: any) => {
    return updateObject(state, {show: true});
};

const hideSpinnerModal = (state: any, action: any) => {
    return updateObject(state, {show: false});
};

const setSpinnerMessage = (state: any, action: any) => {
    return updateObject(state, {message: action.message});
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SPINNER_MODAL_SHOW:
            return showSpinnerModal(state, action);
        case actionTypes.SPINNER_MODAL_HIDE:
            return hideSpinnerModal(state, action);
        case actionTypes.SPINNER_SET_MESSAGE:
            return setSpinnerMessage(state, action)
        default:
            return state;
    }
};

export default reducer;
