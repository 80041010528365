import {FormInterface} from "../../store/declarations";

export const updateObject = (oldObject: any, updatedProperties: any) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export function keys<O extends object>(obj: O): Array<keyof O> {
    return Object.keys(obj) as Array<keyof O>;
}

export const isBase64Image = (value: string) => {
    let result = false;
    const mime = value.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = true;
    }

    return result;
};

export const getImageDataURL = (url: string) => {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

export const validateEmails = (field: any) => {
    if (!field || !field.value || !field.value.elementConfig || !field.value.elementConfig.email) {
        return true;
    }

    const mails = field.value.value;
    if (mails === '') {
        return true;
    }

    let valid = true;
    mails.split(', ').forEach((email: any) => {
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
            valid = false;
        }
    })

    return valid
};

export const collectEmailAddresses = (activeForm: FormInterface) => {
    let addresses: any[] = [];
    for (let form of Object.keys(activeForm.form_data)) {
        const {value, elementConfig} = activeForm.form_data[form];

        if (value && elementConfig.type === 'text') {
            addresses.push(value);
        }
    }

    return addresses.length ? addresses.join(', ') : '';
};
