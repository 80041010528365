import {FormDataInterface, FormInterface} from "../../../declarations";
import {buttonExit, buttonSave} from "../../controllButtons/controllButtons";
import {seal_number, name} from "../../inputFields/inputFields";

const profile: FormDataInterface = {
    name: {
        ...name,
        label: 'Név',
    },
    seal_number
};

const forms: FormInterface = {
    form_name: "Profile from",
    form_data: profile,
    buttons: [
        buttonExit,
        buttonSave
    ]
};

export default forms;
