import React, {useState} from 'react';
import {IonContent, IonPage, IonSearchbar} from "@ionic/react";
import {RouteComponentProps} from "react-router";

import EventList from "../../../components/EventList/EventList";
import Header from "../../../components/Header/Header";
import {doFilter} from "../../../utils/functions/filter";
import {EventState} from "../../../store/states";

interface ListProps extends RouteComponentProps {
    showSearchBar: boolean;
}

const List: React.FC<ListProps> = (props) => {
    const {setEvents, events} = EventState();
    const [searchQuery, updateSearchQuery] = useState();

    const searchBarChangeHandler = (event: any) => {
        const query = event.detail.value!;

        updateSearchQuery(query);
        doFilter(query, events, setEvents);
    };

    const searchBar = () => {
        if (props.showSearchBar) {
            return <IonSearchbar
                value={searchQuery}
                onIonChange={searchBarChangeHandler}
                showCancelButton="focus"
                animated
                placeholder="Keresés"/>;
        }

        return null;
    };

    return (
        <IonPage>
            <IonContent>
                {searchBar()}
                <Header/>

                <div className="ion-padding">
                    <EventList events={events} {...props}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default List;
