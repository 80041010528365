import {FormDataInterface} from "../../../../declarations";
import {
    absence_in_days,
    accreditation_back,
    accreditation_front,
    accreditation_no,
    accreditation_type,
    complaints,
    date_of_birth,
    email,
    first_name,
    last_name,
    nationality,
    other_photo,
    patient_doctor_signature,
    patient_photo,
    privacy, privacy_button,
    sex,
    signature,
    sports,
    time_of_injury
} from "../../../inputFields/inputFields";

const medical_certificate: FormDataInterface = {
    first_name,
    last_name,
    date_of_birth,
    nationality,
    sex,
    email,
    accreditation_front,
    accreditation_back,
    patient_photo,
    other_photo,
    accreditation_no,
    accreditation_type,
    sports,
    complaints,
    time_of_injury,
    absence_in_days,
    privacy,
    privacy_button,
    signature,
    patient_doctor_signature,
};

export default medical_certificate;
