import * as actionTypes from "../actions/actionTypes";
import {EventInterface} from "../declarations";
import {updateObject} from "../../utils/functions/common";

const initialState: EventInterface = {
    id: -1,
    name: '',
    address: '',
    city: '',
    logo: '',
    sports: '',
    hospitals: [],
    accreditation: [],
    emails: '',
    start: '',
    end: '',
    filtered: false,
    name_en: '',
    place_of_care: [],
    covid_text: "",
    insurance_text: "",
    insurance_text_en: "",
};

const setEvent = (state: any, action: any) => {
    return updateObject(state, action.event);
};

const clearEvent = (state: any, action: any) => {
    return updateObject(state, initialState);
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EVENT:
            return setEvent(state, action);
        case actionTypes.EVENT_CLEAR:
            return clearEvent(state, action);
        default:
            return state;
    }
};

export default reducer;
