import ActiveForm from "./ActiveForm";
import Chart from "./Chart";
import Event from "./Event";
import User from "./User";
import Alert from "./Alert";
import Auth from "./Auth";
import Hospital from "./Hospital";
import SpinnerModal from "./SpinnerModal";
import Sync from "./Sync";
import Toast from "./Toast";

export const ActiveFormState = () => ActiveForm();
export const ChartState = () => Chart();
export const EventState = () => Event();
export const UserState = () => User();
export const AlertState = () => Alert();
export const AuthState = () => Auth();
export const HospitalState = () => Hospital();
export const SpinnerModalState = () => SpinnerModal();
export const SyncState = () => Sync();
export const ToastState = () => Toast();
