import classes from "./ControlButtons.module.scss";
import {IonButton} from "@ionic/react";
import React from "react";
import {FormButtonInterface} from "../../../store/declarations";
import {useSelector} from "react-redux";
import Guard from "../../../hoc/guard/guard";

interface ControlButtonsInterface {
    buttonActions: any;
}

const ControlButtons: React.FC<ControlButtonsInterface> = ({buttonActions}) => {
    const {form: activeForm} = useSelector((state: any) => state.activeForm);

    const dynamicButtons = () => {
        if (activeForm && activeForm.buttons) {
            return activeForm.buttons.map((button: FormButtonInterface, index: number) => {
                return (
                    <Guard roles={button.roles || []} key={index}>
                        <IonButton onClick={buttonActions[button.action]} color={button.type}>
                            {button.text}
                        </IonButton>
                    </Guard>
                )
            });
        }
    };

    const getContainerClasses = () => {
        const containerClass = [classes['control-buttons'], 'ion-padding'];

        if (activeForm.buttons.length === 3) {
            containerClass.push(classes['control-buttons--three-column']);
        }

        if (activeForm.buttons.length === 4) {
            containerClass.push(classes['control-buttons--four-column']);
        }

        return containerClass.join(' ');
    };

    return (
        <div className={getContainerClasses()}>
            {dynamicButtons()}
        </div>
    )
};

export default ControlButtons;
